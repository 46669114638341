import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { Icon } from "@opendash/icons";
import { Timeline, Tooltip } from "antd";
// Importieren des quarterOfYear-Plugins
import quarterOfYear from "dayjs/plugin/quarterOfYear";

// Erweiterung von dayjs mit dem quarterOfYear-Plugin
dayjs.extend(quarterOfYear);

interface TimeLineProps {
  selectedYear: number;
  selectedMonth: number;
  currentUser: Parse.User;
}

export const TimeLine: React.FC<TimeLineProps> = ({
  selectedYear,
  selectedMonth,
  currentUser,
}) => {
  const [timeLineItems, setTimeLineItems] = React.useState<any>([]);

  const [trackingData, setTrackingData] = React.useState<any>([]);
  const [customerTrackingData, setCustomerTrackingData] = React.useState<any>(
    []
  );

  const mergedArrow = false;

  React.useEffect(() => {
    getTimeTrackingDataForUser();
  }, [selectedYear, selectedMonth, currentUser]);

  React.useEffect(() => {
    createTimeLineItems(trackingData, customerTrackingData);
  }, [trackingData, customerTrackingData]);

  const getTimeTrackingDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      query.descending("Date");

      let startDate: Date;
      let endDate: Date;

      if (selectedMonth >= 0 && selectedMonth <= 11) {
        // Monat ausgewählt
        startDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
          .startOf("month")
          .toDate();
        endDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
          .endOf("month")
          .toDate();
      } else if (selectedMonth >= 12 && selectedMonth <= 15) {
        // Quartal ausgewählt
        const quarter = selectedMonth - 12 + 1; // Quartalnummer (1-4)
        startDate = dayjs()
          .year(selectedYear)
          .quarter(quarter)
          .startOf("quarter")
          .toDate();
        endDate = dayjs()
          .year(selectedYear)
          .quarter(quarter)
          .endOf("quarter")
          .toDate();
      } else if (selectedMonth === 16) {
        // Ganzes Jahr ausgewählt
        startDate = dayjs().year(selectedYear).startOf("year").toDate();
        endDate = dayjs().year(selectedYear).endOf("year").toDate();
      } else {
        // Standardfall: aktueller Monat
        startDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
          .startOf("month")
          .toDate();
        endDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
          .endOf("month")
          .toDate();
      }

      // Filter auf den ausgewählten Zeitraum setzen
      query.greaterThanOrEqualTo("Date", startDate);
      query.lessThan("Date", endDate);

      const result = await query.find();
      let foundObject: any = result.filter(
        (obj: any) => obj.get("User").id === currentUser?.id
      );
      await getCustomerTimeTrackingDataForUser();
      if (foundObject && foundObject.length > 0) {
        setTrackingData(foundObject);
      } else {
        setTrackingData([]);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  const getCustomerTimeTrackingDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_TimeTrack").limit(99999999);
      query.include("Customer");
      query.include("TimeTracking");
      const result = await query.find();
      setCustomerTrackingData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Kundendaten:", error);
    }
  };

  const createTimeLineItems = (
    timeTrackingItems: Parse.Object[],
    customerTimeTrackingItems: Parse.Object[]
  ) => {
    let items: Object[] = [];
    timeTrackingItems.forEach((element: any) => {
      let customerItems = customerTimeTrackingItems.filter(
        (obj: any) => obj.get("TimeTracking").id === element.id
      );

      if (
        element.get("Comment") !== "" ||
        element.get("Sick") ||
        element.get("Vacation") ||
        customerItems?.length > 0
      ) {
        const day = dayjs(element.get("Date")).date();
        let color = "black";
        let pointIcon = <Icon icon="fa:circle" />;
        const timeFormatted = dayjs(element.get("Date")).format("DD.MM.YYYY");
        const maxLength = 30;
        const maxLengthComment = 45;

        let icon = <Icon icon="fa:book" />;
        let text: JSX.Element[] = [];

        if (element.get("Sick")) {
          color = "red";
          icon = <Icon icon="fa:virus" />;
          text.push(<p>{icon} Krankheit</p>);
        }
        if (element.get("Vacation")) {
          color = "#d3d52e";
          icon = <Icon icon="fa:umbrella-beach" />;
          text.push(<p>{icon} Urlaub</p>);
        }
        if (
          element.get("Comment") !== "" &&
          !element.get("Vacation") &&
          !element.get("Sick")
        ) {
          if (element.get("Comment").includes("Fraunhofer")) {
            color = "#7fbb47";
          }
        }

        if (customerItems?.length > 0) {
          color = "#4385c2";
          icon = <Icon icon="fa:building" />;
          let iconEuro = <Icon icon="fa:euro-sign" />;
          customerItems.forEach((customer: any, idx: number) => {
            const isLong =
              customer.get("Customer").get("Name").length > maxLength;
            const shortComment = isLong
              ? customer.get("Customer").get("Name").substring(0, maxLength) +
                "..."
              : customer.get("Customer").get("Name");

            const calcHoursADay = customer.get("Worktime");
            const hours = Math.floor(calcHoursADay / 60);
            const minutes = calcHoursADay % 60;
            const formattedWorktime = `${hours}:${minutes
              .toString()
              .padStart(2, "0")} Std.`;

            text.push(
              <Tooltip
                key={`customer-${idx}`}
                title={customer.get("Customer").get("Name")}
                placement="topLeft"
                arrow={mergedArrow}
              >
                <p>
                  {icon}
                  {customer.get("Billable") && iconEuro}{" "}
                  {shortComment + " | " + formattedWorktime}
                  <br />
                  <div style={{ fontSize: "0.9em" }}>
                    {customer.get("Comment")}
                  </div>
                </p>
              </Tooltip>
            );
          });
        }
        if (
          element.get("Comment") !== "" &&
          !element.get("Vacation") &&
          !element.get("Sick")
        ) {
          icon = <Icon icon="fa:book" />;
          const isLong = element.get("Comment").length > maxLengthComment;
          const shortComment = isLong
            ? element.get("Comment").substring(0, maxLengthComment) + "..."
            : element.get("Comment");

          text.push(
            <Tooltip
              title={element.get("Comment")}
              placement="topLeft"
              arrow={mergedArrow}
            >
              <p>
                {icon}
                {" " + shortComment}
              </p>
            </Tooltip>
          );
        }

        let closeText = <>{text}</>;

        items.push({
          label: timeFormatted,
          dot: pointIcon,
          color: color,
          children: closeText,
        });
      }
    });
    setTimeLineItems(items);
  };

  return <Timeline items={timeLineItems} mode="left" />;
};
