import Parse from "parse";
import { AppFactory, AppPluginInterface } from "@opendash/core";
import { ProjectComponent } from "./components/AdminPages/ProjectComponent";
import { $parse, CustomParseFieldProps } from "@opendash/plugin-parse";
import { ProjectRequestComponent } from "./components/ProjectRequestComponent";
import { ProjectInvoiceComponent } from "./components/ProjectInvoiceComponent";
import { ProjectTripComponent } from "./components/ProjectTripComponent";
import { SelectPosition } from "./components/Pieces/SelectPosition";
import { ProjectOverviewComponent } from "./components/ProjectOverviewComponent";
import { MoneyCallComponent } from "./components/AdminPages/MoneyCallComponent";
import { SelectAbrufart } from "./components/Pieces/SelectAbrufart";
import { ProjectContractComponent } from "./components/AdminPages/ProjectContractComponent";
import { ProjectReportingComponent } from "./components/ProjectReportingComponent";
import { SelectContract } from "./components/Pieces/SelectContract";
import { SelectDate } from "./components/Pieces/SelectDate";
import { TextMulti } from "./components/Pieces/TextMulti";
import { SelectProject } from "./components/Pieces/SelectProject";

export interface Options {}

export class ResearchProjectPlugin implements AppPluginInterface {
  name: string = "ResearchProjectPlugin";

  private options: Partial<Options> = {};

  constructor(options?: Partial<Options>) {
    this.options = options || {};
  }

  async onFactory(factory: AppFactory) {
    factory.registerRoute({
      path: "/admin/parse/projects",
      props: this.options,
      component: async () => ({ default: ProjectComponent }),
      permission: "project:admin",
      headerSourcePicker: false,
    });

    factory.registerRoute({
      path: "/admin/parse/moneycalls",
      props: this.options,
      component: async () => ({ default: MoneyCallComponent }),
      permission: "project:admin",
      headerSourcePicker: false,
    });

    factory.registerRoute({
      path: "/admin/parse/projectcontract",
      props: this.options,
      component: async () => ({ default: ProjectContractComponent }),
      permission: "project:admin",
      headerSourcePicker: false,
    });

    factory.registerRoute({
      path: "/researchprojects/overview",
      props: this.options,
      component: async () => ({ default: ProjectOverviewComponent }),
      permission: "project:view",
      headerSourcePicker: false,
    });

    factory.registerRoute({
      path: "/researchprojects/reporting",
      props: this.options,
      component: async () => ({ default: ProjectReportingComponent }),
      permission: "project:view",
      headerSourcePicker: false,
    });

    factory.registerRoute({
      path: "/researchprojects/request",
      props: this.options,
      component: async () => ({ default: ProjectRequestComponent }),
      permission: "project:costs",
      headerSourcePicker: false,
    });

    factory.registerRoute({
      path: "/researchprojects/invoice",
      props: this.options,
      component: async () => ({ default: ProjectInvoiceComponent }),
      permission: "project:costs",
      headerSourcePicker: false,
    });

    factory.registerRoute({
      path: "/researchprojects/trips",
      props: this.options,
      component: async () => ({ default: ProjectTripComponent }),
      permission: "project:costs",
      headerSourcePicker: false,
    });

    factory.registerStaticNavigationItem({
      id: "researchprojects/request",
      place: "frontpage",
      group: "researchprojects",
      order: 80,
      color: "#676767",
      label: "Forschungsprojekte",
      icon: "fa:search",
      link: "/researchprojects/overview",
      routeCondition: "**",
      activeCondition: "/researchprojects/overview",
      permission: "project:view",
    });

    factory.registerStaticNavigationGroup({
      label: "Forschungsprojekte",
      order: 20,
      id: "researchprojects/projects",
    });

    factory.registerStaticNavigationGroup({
      label: "Forschungsprojekte",
      order: 20,
      id: "admin/researchprojects",
    });

    factory.registerStaticNavigationItem({
      id: "admin/projectadmin",
      group: "admin/researchprojects",
      place: "sidebar",
      order: 10,
      label: "Projekte",
      icon: "fa:folder",
      link: "/admin/parse/projects",
      routeCondition: "/admin/**",
      activeCondition: "/admin/parse/projects",
      permission: "project:admin",
    });

    factory.registerStaticNavigationItem({
      id: "admin/projectcontractadmin",
      group: "admin/researchprojects",
      place: "sidebar",
      order: 20,
      label: "Projektverträge",
      icon: "fa:file-contract",
      link: "/admin/parse/projectcontract",
      routeCondition: "/admin/**",
      activeCondition: "/admin/parse/projectcontract",
      permission: "project:admin",
    });

    factory.registerStaticNavigationItem({
      id: "admin/projectcalladmin",
      group: "admin/researchprojects",
      place: "sidebar",
      order: 30,
      label: "Abgerufene Mittels",
      icon: "fa:money-check-alt",
      link: "/admin/parse/moneycalls",
      routeCondition: "/admin/**",
      activeCondition: "/admin/parse/moneycalls",
      permission: "project:admin",
    });

    factory.registerStaticNavigationItem({
      id: "researchprojects/overviewside",
      group: "researchprojects/projects",
      place: "sidebar",
      order: 10,
      label: "Übersicht",
      icon: "fa:search",
      link: "/researchprojects/overview",
      routeCondition: "/researchprojects/**",
      activeCondition: "/researchprojects/overview",
      permission: "project:view",
    });

    factory.registerStaticNavigationItem({
      id: "researchprojects/report",
      group: "researchprojects/projects",
      place: "sidebar",
      order: 20,
      label: "Reporting",
      icon: "fa:file-alt",
      link: "/researchprojects/reporting",
      routeCondition: "/researchprojects/**",
      activeCondition: "/researchprojects/reporting",
      permission: "project:view",
    });

    factory.registerStaticNavigationItem({
      id: "researchprojects/requestside",
      group: "researchprojects/projects",
      place: "sidebar",
      order: 30,
      label: "Mittelabruf",
      icon: "fa:funnel-dollar",
      link: "/researchprojects/request",
      routeCondition: "/researchprojects/**",
      activeCondition: "/researchprojects/request",
      permission: "project:costs",
    });

    factory.registerStaticNavigationItem({
      id: "researchprojects/invoiceside",
      group: "researchprojects/projects",
      place: "sidebar",
      order: 40,
      label: "Rechnungen",
      icon: "fa:file-invoice-dollar",
      link: "/researchprojects/invoice",
      routeCondition: "/researchprojects/**",
      activeCondition: "/researchprojects/invoice",
      permission: "project:costs",
    });

    factory.registerStaticNavigationItem({
      id: "researchprojects/tripside",
      group: "researchprojects/projects",
      place: "sidebar",
      order: 50,
      label: "Forschungsreisen",
      icon: "fa:car",
      link: "/researchprojects/trips",
      routeCondition: "/researchprojects/**",
      activeCondition: "/researchprojects/trips",
      permission: "project:costs",
    });

    $parse.ui.setClassConfig({
      className: "OD3_Projects",
      customFilter: (query: Parse.Query) => {
        query.descending("Start");
      },
      disableACLEditing: true,
      titleFields: ["Name"],
      displayFields: [
        "Name",
        "Start",
        "End",
        "Forderart",
        "Jahresstunden",
        "Forderquote",
        "Overhead",
        "TotalMoney",
      ],
      createFields: [
        "Name",
        "Start",
        "End",
        "Forderart",
        "Jahresstunden",
        "Forderquote",
        "Overhead",
        "TotalMoney",
      ],
      editFields: [
        "Name",
        "Start",
        "End",
        "Forderart",
        "Jahresstunden",
        "Forderquote",
        "Overhead",
        "TotalMoney",
      ],
      defaultACL: () => {
        const acl: { [key: string]: { read: boolean; write: boolean } } = {};
        const tenantId = $parse.user.current()?.get("tenant")?.id;
        if (tenantId) {
          acl["role:od-tenant-user-" + tenantId] = {
            read: true,
            write: false,
          };
          acl["role:od-tenant-admin-" + tenantId] = {
            read: true,
            write: true,
          };
        }
        return acl;
      },
    });

    $parse.ui.setClassConfig({
      className: "OD3_Invoice",
      customFilter: (query: Parse.Query) => {
        query.descending("InvoiceDate");
      },
      disableACLEditing: true,
      titleFields: ["InvoiceDate", "Name"],
      displayFields: [
        "Name",
        "Position",
        "InvoiceDate",
        "Description",
        "Supplier",
        "Netto",
        "Brutto",
        "Duration",
        "Project",
        "GWG",
        "Invoice",
      ],
      createFields: [
        "Name",
        "Position",
        "InvoiceDate",
        "Description",
        "Supplier",
        "Netto",
        "Brutto",
        "Duration",
        "Project",
        "GWG",
        "Invoice",
      ],
      editFields: [
        "Name",
        "Position",
        "InvoiceDate",
        "Description",
        "Supplier",
        "Netto",
        "Brutto",
        "Duration",
        "Project",
        "GWG",
        "Invoice",
      ],
      customFields: {
        Position: (val: CustomParseFieldProps) => {
          return SelectPosition(val);
        },
      },
      defaultACL: () => {
        const acl: { [key: string]: { read: boolean; write: boolean } } = {};
        const tenantId = $parse.user.current()?.get("tenant")?.id;
        if (tenantId) {
          acl["role:od-tenant-admin-" + tenantId] = {
            read: true,
            write: true,
          };
        }
        return acl;
      },
    });

    $parse.ui.setClassConfig({
      className: "OD3_Trip",
      customFilter: (query: Parse.Query) => {
        query.descending("Start");
      },
      disableACLEditing: true,
      titleFields: ["Start", "End"],
      displayFields: [
        "Employee",
        "Project",
        "Start",
        "End",
        "Description",
        "Costs",
        "Documents",
      ],
      createFields: [
        "Employee",
        "Project",
        "Start",
        "End",
        "Description",
        "Costs",
        "Documents",
      ],
      editFields: [
        "Employee",
        "Project",
        "Start",
        "End",
        "Description",
        "Costs",
        "Documents",
      ],
      defaultACL: () => {
        const acl: { [key: string]: { read: boolean; write: boolean } } = {};
        const tenantId = $parse.user.current()?.get("tenant")?.id;
        if (tenantId) {
          acl["role:od-tenant-admin-" + tenantId] = {
            read: true,
            write: true,
          };
        }
        return acl;
      },
    });

    $parse.ui.setClassConfig({
      className: "OD3_ProjectCalls",
      customFilter: (query: Parse.Query) => {
        query.descending("Anforderungsdatum");
      },
      disableACLEditing: true,
      titleFields: ["Anforderungsdatum", "Projekt"],
      displayFields: [
        "Anforderungsdatum",
        "Projekt",
        "Abrufssumme",
        "Abrufart",
      ],
      createFields: ["Anforderungsdatum", "Projekt", "Abrufssumme", "Abrufart"],
      editFields: ["Anforderungsdatum", "Projekt", "Abrufssumme", "Abrufart"],
      customFields: {
        Abrufart: (val: CustomParseFieldProps) => {
          return SelectAbrufart(val);
        },
      },
      defaultACL: () => {
        const acl: { [key: string]: { read: boolean; write: boolean } } = {};
        const tenantId = $parse.user.current()?.get("tenant")?.id;
        if (tenantId) {
          acl["role:od-tenant-admin-" + tenantId] = {
            read: true,
            write: true,
          };
        }
        return acl;
      },
    });

    $parse.ui.setClassConfig({
      className: "OD3_Project_Documents",
      customFilter: (query: Parse.Query) => {
        query.descending("createdAt");
      },
      disableACLEditing: true,
      titleFields: ["Project", "Document"],
      displayFields: ["Project", "Document"],
      createFields: ["Project", "Document"],
      editFields: ["Project", "Document"],
      defaultACL: () => {
        const acl: { [key: string]: { read: boolean; write: boolean } } = {};
        const tenantId = $parse.user.current()?.get("tenant")?.id;
        if (tenantId) {
          acl["role:od-tenant-admin-" + tenantId] = {
            read: true,
            write: true,
          };
        }
        return acl;
      },
    });

    $parse.ui.setClassConfig({
      className: "OD3_ProjectReport",

      customFilter: (query: Parse.Query) => {
        query.descending("Bis");
      },
      disableACLEditing: true,
      titleFields: ["Projekt", "Von", "Bis"],
      displayFields: [
        "Projekt",
        "Mitarbeiter",
        "Von",
        "Bis",
        "Beschreibung",
        "Anhang",
      ],
      createFields: [
        "Projekt",
        "Mitarbeiter",
        "Von",
        "Bis",
        "Beschreibung",
        "Anhang",
      ],
      editFields: [
        "Projekt",
        "Mitarbeiter",
        "Von",
        "Bis",
        "Beschreibung",
        "Anhang",
      ],
      customFields: {
        Von: (val: CustomParseFieldProps) => {
          return SelectDate(val);
        },
        Bis: (val: CustomParseFieldProps) => {
          return SelectDate(val);
        },
        Beschreibung: (val: CustomParseFieldProps) => {
          return TextMulti(val);
        },
        Projekt: (val: CustomParseFieldProps) => {
          return SelectProject(val);
        },
      },

      defaultACL: () => {
        const acl: { [key: string]: { read: boolean; write: boolean } } = {};
        const tenantId = $parse.user.current()?.get("tenant")?.id;
        if (tenantId) {
          acl["role:od-tenant-admin-" + tenantId] = {
            read: true,
            write: true,
          };
        }
        if ($parse.user.current()) {
          // @ts-ignores
          acl[$parse.user.current()?.id] = {
            read: true,
            write: true,
          };
        }
        return acl;
      },
      afterCreate: async (classname: string, objectId: string) => {
        const query = new Parse.Query(classname);
        query.equalTo("objectId", objectId);
        const obj = await query.get(objectId);
        obj.set("Mitarbeiter", $parse.user.current());
        await obj.save();
      },
    });

    $parse.ui.setClassConfig({
      className: "OD3_ProjectContract",

      customFilter: (query: Parse.Query) => {
        query.descending("Start");
      },
      disableACLEditing: true,
      titleFields: ["Vertrag", "Projekt"],
      displayFields: ["Vertrag", "Projekt", "Start", "End"],
      createFields: ["Vertrag", "Projekt", "Start", "End"],
      editFields: ["Vertrag", "Projekt", "Start", "End"],
      customFields: {
        Vertrag: (val: CustomParseFieldProps) => {
          return SelectContract(val);
        },
      },
      defaultACL: () => {
        const acl: Record<string, { read?: boolean; write?: boolean }> = {};
        const tenantId = $parse.user.current()?.get("tenant")?.id;
        if (tenantId) {
          acl["role:od-tenant-admin-" + tenantId] = {
            read: true,
            write: true,
          };
        }
        return acl;
      },
      afterCreate: async (classname: string, objectId: string) => {
        const query = new Parse.Query(classname);
        query.equalTo("objectId", objectId);
        query.include("Vertrag");
        const obj = await query.get(objectId);
        const vertrag = obj.get("Vertrag");
        obj.setACL(vertrag.getACL());
        await obj.save();
      },
    });

    // Translations:
    factory.registerTranslationResolver(
      "en",
      "researchproject",
      async () => await import("./translations/en.json")
    );

    factory.registerTranslationResolver(
      "de",
      "researchproject",
      async () => await import("./translations/de.json")
    );
  }
}
