import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { color, Options } from "highcharts";
import { HighchartsChart } from "@opendash/plugin-highcharts";
import { Modal, DatePicker, Alert, Space, message } from "antd";
import { Loading } from "@opendash/core";

// Importieren und Erweitern des quarterOfYear-Plugins
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);

const colors = [
  "#7fbb47",
  "#4385c2",
  "#676767",
  "#d3d52e",
  "#f39c12",
  "#e74c3c",
  "#8e44ad",
  "#3498db",
  "#2ecc71",
  "#1abc9c",
  "#d35400",
  "#c0392b",
  "#2980b9",
  "#27ae60",
];

interface ChartsProps {
  selectedYear: number;
  selectedMonth: number;
  currentUser: Parse.User;
}

export const Charts: React.FC<ChartsProps> = ({
  selectedYear,
  selectedMonth,
  currentUser,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [vacationDays, setVacationDays] = React.useState<number>(0);
  const [sickDays, setSickDays] = React.useState<number>(0);
  const [workDays, setWorkDays] = React.useState<number>(0);
  const [homeDays, setHomeDays] = React.useState<number>(0);
  const [officeDays, setOfficeDays] = React.useState<number>(0);
  const [projectHours, setProjectHours] = React.useState<number>(0);
  const [customerHours, setCustomerHours] = React.useState<number>(0);
  const [internalHours, setInternalHours] = React.useState<number>(0);
  const [otherHours, setOtherHours] = React.useState<number>(0);
  const [billableHours, setBillableHours] = React.useState<number>(0);
  const [notBillableHours, setNotBillableHours] = React.useState<number>(0);
  const [customerChart, setCustomerChart] = React.useState<any[]>([]);
  const [contractWorkingHours, setContractWorkingHours] =
    React.useState<number>(8);

  React.useEffect(() => {
    setIsLoading(true);
    getCustomerTimeTrackingDataForUser();
    initUserMeta();
  }, [selectedYear, selectedMonth, currentUser]);

  const initUserMeta = () => {
    const query = new Parse.Query("OD3_Contract").limit(99999999);

    let startDate: Date;
    let endDate: Date;

    // Bestimme den Zeitraum basierend auf der Auswahl
    if (selectedMonth >= 0 && selectedMonth <= 11) {
      // Monat ausgewählt
      startDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
        .startOf("month")
        .toDate();
      endDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
        .endOf("month")
        .toDate();
    } else if (selectedMonth >= 12 && selectedMonth <= 15) {
      // Quartal ausgewählt
      const quarter = selectedMonth - 12 + 1; // Quartalnummer (1-4)
      startDate = dayjs()
        .year(selectedYear)
        .quarter(quarter)
        .startOf("quarter")
        .toDate();
      endDate = dayjs()
        .year(selectedYear)
        .quarter(quarter)
        .endOf("quarter")
        .toDate();
    } else if (selectedMonth === 16) {
      // Ganzes Jahr ausgewählt
      startDate = dayjs().year(selectedYear).startOf("year").toDate();
      endDate = dayjs().year(selectedYear).endOf("year").toDate();
    } else {
      // Standardfall: aktueller Monat
      startDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
        .startOf("month")
        .toDate();
      endDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
        .endOf("month")
        .toDate();
    }

    // Abfrage anpassen
    query.equalTo("User", currentUser);
    query.find().then((results: any) => {
      const filteredObjects = results.filter((obj: any) => {
        const contractStartDate = obj.get("Start");
        const contractEndDate = obj.get("End");

        return (
          contractStartDate <= endDate && contractEndDate >= startDate // Überprüfe, ob der Vertrag innerhalb des Zeitraums liegt
        );
      });

      // Durchschnitt berechnen
      const totalValue = filteredObjects.reduce((sum: number, obj: any) => {
        const value = obj.get("HoursADay"); // Ersetze "HoursADay" durch das entsprechende Feld
        return sum + (value || 0); // Füge einen Fallback hinzu, falls der Wert undefined ist
      }, 0);

      const average =
        filteredObjects.length > 0 ? totalValue / filteredObjects.length : 0;

      setContractWorkingHours(average);
    });
  };

  const getCustomerTimeTrackingDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_TimeTrack").limit(99999999);
      query.include("Customer");
      query.include("TimeTracking");
      const result = await query.find();
      getChartItems(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  const getChartItems = async (customerData: any[]) => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      query.descending("Date");

      let startDate: Date;
      let endDate: Date;

      if (selectedMonth >= 0 && selectedMonth <= 11) {
        // Monat ausgewählt
        startDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
          .startOf("month")
          .toDate();
        endDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
          .endOf("month")
          .toDate();
      } else if (selectedMonth >= 12 && selectedMonth <= 15) {
        // Quartal ausgewählt
        const quarter = selectedMonth - 12 + 1; // Quartalnummer (1-4)
        startDate = dayjs()
          .year(selectedYear)
          .quarter(quarter)
          .startOf("quarter")
          .toDate();
        endDate = dayjs()
          .year(selectedYear)
          .quarter(quarter)
          .endOf("quarter")
          .toDate();
      } else if (selectedMonth === 16) {
        // Ganzes Jahr ausgewählt
        startDate = dayjs().year(selectedYear).startOf("year").toDate();
        endDate = dayjs().year(selectedYear).endOf("year").toDate();
      } else {
        // Standardfall: aktueller Monat
        startDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
          .startOf("month")
          .toDate();
        endDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
          .endOf("month")
          .toDate();
      }

      // Filter auf den ausgewählten Zeitraum setzen
      query.greaterThanOrEqualTo("Date", startDate);
      query.lessThan("Date", endDate);

      const result = await query.find();
      let foundObject: any = result.filter(
        (obj: any) => obj.get("User").id === currentUser?.id
      );

      // Initialisierung der Variablen
      let vacationDays = 0;
      let sickDays = 0;
      let workDays = 0;

      let homeDays = 0;
      let officeDays = 0;

      let projectHours = 0;
      let customerHours = 0;
      let internalHours = 0;
      let otherHours = 0;

      let billableHours = 0;
      let notBillableHours = 0;

      const resultss: React.SetStateAction<any[]> = [];
      const customerMap: { [key: string]: any } = {};

      if (foundObject && foundObject.length > 0) {
        foundObject.forEach((element: any) => {
          let customerItems = customerData.filter(
            (obj: any) => obj.get("TimeTracking").id === element.id
          );

          if (element.get("Vacation")) {
            vacationDays++;
          } else if (element.get("Sick")) {
            sickDays++;
          } else {
            workDays++;
          }

          if (element.get("HomeOffice")) {
            homeDays++;
          } else {
            officeDays++;
          }

          otherHours += element.get("WorkingHours");
          const customer = customerItems;
          const research = element.get("Research");

          customer.forEach((customerElement: any, index: number) => {
            if (
              customerElement.get("Customer").get("Name") === "open.INC GmbH"
            ) {
              internalHours += parseFloat(customerElement.get("Worktime"));
            } else {
              customerHours += parseFloat(customerElement.get("Worktime"));
            }
            if (customerElement.get("Billable")) {
              billableHours += parseFloat(customerElement.get("Worktime"));
            } else {
              notBillableHours += parseFloat(customerElement.get("Worktime"));
            }
            try {
              const name = customerElement.get("Customer").get("Name");
              const worktime = parseFloat(customerElement.get("Worktime"));
              if (!customerMap[name]) {
                customerMap[name] = { name: name, y: 0 };
                resultss.push(customerMap[name]);
              }
              customerMap[name].y += worktime / 60;
              customerMap[name]._unit = "Std.";
            } catch (error) {
              console.error(
                "Fehler beim Abrufen der TimeTracking-Daten:",
                error
              );
            }
          });

          if (research && Array.isArray(research)) {
            research.forEach((researchElement: any) => {
              projectHours += parseFloat(researchElement.worktime);
            });
          }
        });
      }
      setVacationDays(vacationDays);
      setSickDays(sickDays);
      setWorkDays(workDays);
      setHomeDays(homeDays);
      setOfficeDays(officeDays);
      setProjectHours(projectHours / 60);
      setCustomerHours(customerHours / 60);
      setInternalHours(internalHours / 60);
      setOtherHours(
        otherHours / 60 -
          projectHours / 60 -
          customerHours / 60 -
          internalHours / 60
      );
      setBillableHours(billableHours / 60);
      setNotBillableHours(notBillableHours / 60);
      setCustomerChart(resultss);
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
    setIsLoading(false);
  };

  const seriesDataHours = React.useMemo(() => {
    return [
      {
        name: "Forschungsprojekt",
        y: projectHours,
        color: "#7fbb47",
        _unit: "Std.",
      },
      {
        name: "Kundenprojekt",
        y: customerHours,
        color: "#4385c2",
        _unit: "Std.",
      },
      { name: "Intern", y: internalHours, color: "#676767", _unit: "Std." },
      { name: "Sonstige", y: otherHours, color: "#d3d52e", _unit: "Std." },
    ];
  }, [projectHours, customerHours, internalHours, internalHours]);

  const seriesDataCustomer = React.useMemo(() => {
    return [
      {
        name: "Abrechenbare Stunden",
        y: billableHours,
        color: "#4385c2",
        _unit: "Std.",
      },
      {
        name: "Nicht abrechenbare Stunden",
        y: notBillableHours,
        color: "#676767",
        _unit: "Std.",
      },
      {
        name: "Abwesenheit",
        y: (vacationDays + sickDays) * contractWorkingHours,
        color: "#d3d52e",
        _unit: "Std.",
      },
      {
        name: "Forschungsprojekt",
        y: projectHours,
        color: "#7fbb47",
        _unit: "Std.",
      },
    ];
  }, [billableHours, notBillableHours, projectHours, vacationDays, sickDays]);

  const optionsHours = React.useMemo<Options>(() => {
    const result: Options = {
      title: null,
      subtitle: null,

      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },

      tooltip: {
        formatter: function () {
          let returnString = "";
          let nameString = "";
          let valueString = "";
          let totalString = "";
          let percentString = "";
          nameString = this.key + "";
          valueString =
            '<p style="font-size: 13px">' +
            "Wert: " +
            ": <b>" +
            this.y.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          totalString =
            '<p style="font-size: 13px">' +
            "Total: " +
            ": <b>" +
            this.total.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          percentString =
            '<p style="font-size: 13px">' +
            "Anteil: " +
            ": <b>" +
            ((this.y / this.total) * 100).toFixed(2) +
            " %</b></p>";

          returnString =
            '<p style="font-size: 13px"><b>' + nameString + "</b></p><br/>";
          returnString += valueString;
          returnString += totalString;
          returnString += percentString;
          return returnString;
        },
        shadow: false,
        borderWidth: 1,
        outside: true,
        borderColor: "rgba(100,100,100,0.3)",
        backgroundColor: "rgba(255,255,255,0.95)",
      },

      plotOptions: {
        pie: {
          size: "60%",
          dataLabels: {
            enabled: true,
            distance: 18,
            crop: true,
            padding: 0,
            overflow: "justify",
            formatter: function () {
              return (
                '<p style="font-size: 12px;z-index:-1"> ' +
                this.point.name.split("(")[0] +
                ": " +
                this.y?.toFixed(1) +
                " " +
                //@ts-ignore
                this.point._unit +
                "</p>"
              );
            },
          },

          showInLegend: true,

          startAngle: -90,
          endAngle: 360 - 90,
          center: ["50%", "50%"],
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        // @ts-ignore
        {
          data: seriesDataHours,
          innerSize: "80%",
          turboThreshold: 0,
        },
      ],
    };

    return result;
  }, [seriesDataHours]);

  const customerChartOption = React.useMemo<Options>(() => {
    const result: Options = {
      title: null,
      subtitle: null,

      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },

      tooltip: {
        formatter: function () {
          let returnString = "";
          let nameString = "";
          let valueString = "";
          let totalString = "";
          let percentString = "";
          nameString = this.key + "";
          valueString =
            '<p style="font-size: 13px">' +
            "Wert: " +
            ": <b>" +
            this.y.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          totalString =
            '<p style="font-size: 13px">' +
            "Total: " +
            ": <b>" +
            this.total.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          percentString =
            '<p style="font-size: 13px">' +
            "Anteil: " +
            ": <b>" +
            ((this.y / this.total) * 100).toFixed(2) +
            " %</b></p>";

          returnString =
            '<p style="font-size: 13px"><b>' + nameString + "</b></p><br/>";
          returnString += valueString;
          returnString += totalString;
          returnString += percentString;
          return returnString;
        },
        shadow: false,
        borderWidth: 1,
        outside: true,
        borderColor: "rgba(100,100,100,0.3)",
        backgroundColor: "rgba(255,255,255,0.95)",
      },

      plotOptions: {
        pie: {
          size: "60%",
          dataLabels: {
            enabled: true,
            distance: 18,
            crop: true,
            padding: 0,
            overflow: "justify",
            formatter: function () {
              const maxLength = 20; // Maximale Länge festlegen
              let name = this.point.name.split("(")[0];
              if (name.length > maxLength) {
                name = name.substring(0, maxLength) + "...";
              }
              return (
                '<p style="font-size: 12px;z-index:-1"> ' +
                name +
                ": " +
                this.y?.toFixed(1) +
                " " +
                //@ts-ignore
                this.point._unit +
                "</p>"
              );
            },
          },

          showInLegend: true,

          startAngle: -90,
          endAngle: 360 - 90,
          center: ["50%", "50%"],
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        // @ts-ignore
        {
          data: customerChart.map((item, index) => ({
            ...item,
            color: colors[index % colors.length], // Farbe zuweisen
          })),
          innerSize: "80%",
          turboThreshold: 0,
        },
      ],
    };

    return result;
  }, [customerChart]);

  const optionCustomer = React.useMemo<Options>(() => {
    const result: Options = {
      title: null,
      subtitle: null,

      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },

      tooltip: {
        formatter: function () {
          let returnString = "";
          let nameString = "";
          let valueString = "";
          let totalString = "";
          let percentString = "";
          nameString = this.key + "";
          valueString =
            '<p style="font-size: 13px">' +
            "Wert: " +
            ": <b>" +
            this.y.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          totalString =
            '<p style="font-size: 13px">' +
            "Total: " +
            ": <b>" +
            this.total.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          percentString =
            '<p style="font-size: 13px">' +
            "Anteil: " +
            ": <b>" +
            ((this.y / this.total) * 100).toFixed(2) +
            " %</b></p>";

          returnString =
            '<p style="font-size: 13px"><b>' + nameString + "</b></p><br/>";
          returnString += valueString;
          returnString += totalString;
          returnString += percentString;
          return returnString;
        },
        shadow: false,
        borderWidth: 1,
        outside: true,
        borderColor: "rgba(100,100,100,0.3)",
        backgroundColor: "rgba(255,255,255,0.95)",
      },

      plotOptions: {
        pie: {
          size: "60%",
          dataLabels: {
            enabled: true,
            distance: 18,
            crop: true,
            padding: 0,
            overflow: "justify",
            formatter: function () {
              return (
                '<p style="font-size: 12px;z-index:-1"> ' +
                this.point.name.split("(")[0] +
                ": " +
                this.y?.toFixed(1) +
                " " +
                //@ts-ignore
                this.point._unit +
                "</p>"
              );
            },
          },

          showInLegend: true,

          startAngle: -90,
          endAngle: 360 - 90,
          center: ["50%", "50%"],
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        // @ts-ignore
        {
          data: seriesDataCustomer,
          innerSize: "80%",
          turboThreshold: 0,
        },
      ],
    };

    return result;
  }, [seriesDataCustomer]);

  const seriesDataOffice = React.useMemo(() => {
    return [
      { name: "Home Office", y: homeDays, color: "#7fbb47", _unit: "Tage" },
      {
        name: "Office Day",
        y: officeDays,
        color: "#4385c2",
        _unit: "Tage",
      },
    ];
  }, [officeDays, homeDays]);

  const optionsOffice = React.useMemo<Options>(() => {
    const result: Options = {
      title: null,
      subtitle: null,

      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },

      tooltip: {
        formatter: function () {
          let returnString = "";
          let nameString = "";
          let valueString = "";
          let totalString = "";
          let percentString = "";
          nameString = this.key + "";
          valueString =
            '<p style="font-size: 13px">' +
            "Wert: " +
            ": <b>" +
            this.y.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          totalString =
            '<p style="font-size: 13px">' +
            "Total: " +
            ": <b>" +
            this.total.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          percentString =
            '<p style="font-size: 13px">' +
            "Anteil: " +
            ": <b>" +
            ((this.y / this.total) * 100).toFixed(2) +
            " %</b></p>";

          returnString =
            '<p style="font-size: 13px"><b>' + nameString + "</b></p><br/>";
          returnString += valueString;
          returnString += totalString;
          returnString += percentString;
          return returnString;
        },
        shadow: false,
        borderWidth: 1,
        outside: true,
        borderColor: "rgba(100,100,100,0.3)",
        backgroundColor: "rgba(255,255,255,0.95)",
      },

      plotOptions: {
        pie: {
          size: "60%",
          dataLabels: {
            enabled: true,
            distance: 18,
            crop: true,
            padding: 0,
            overflow: "justify",
            formatter: function () {
              return (
                '<p style="font-size: 12px;z-index:-1"> ' +
                this.point.name.split("(")[0] +
                ": " +
                this.y +
                " " +
                //@ts-ignore
                this.point._unit +
                "</p>"
              );
            },
          },

          showInLegend: true,

          startAngle: -90,
          endAngle: 360 - 90,
          center: ["50%", "50%"],
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        // @ts-ignore
        {
          data: seriesDataOffice,
          innerSize: "80%",
          turboThreshold: 0,
        },
      ],
    };

    return result;
  }, [seriesDataOffice]);

  const seriesDataDays = React.useMemo(() => {
    return [
      { name: "Krankheitstage", y: sickDays, color: "#c93838", _unit: "Tage" },
      {
        name: "Urlaubstage",
        y: vacationDays,
        color: "#d3d52e",
        _unit: "Tage",
      },
      { name: "Arbeitstage", y: workDays, color: "#4385c2", _unit: "Tage" },
    ];
  }, [sickDays, vacationDays, workDays]);

  const optionsDays = React.useMemo<Options>(() => {
    const result: Options = {
      title: null,
      subtitle: null,

      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },

      tooltip: {
        formatter: function () {
          let returnString = "";
          let nameString = "";
          let valueString = "";
          let totalString = "";
          let percentString = "";
          nameString = this.key + "";
          valueString =
            '<p style="font-size: 13px">' +
            "Wert: " +
            ": <b>" +
            this.y.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          totalString =
            '<p style="font-size: 13px">' +
            "Total: " +
            ": <b>" +
            this.total.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          percentString =
            '<p style="font-size: 13px">' +
            "Anteil: " +
            ": <b>" +
            ((this.y / this.total) * 100).toFixed(2) +
            " %</b></p>";

          returnString =
            '<p style="font-size: 13px"><b>' + nameString + "</b></p><br/>";
          returnString += valueString;
          returnString += totalString;
          returnString += percentString;
          return returnString;
        },
        shadow: false,
        borderWidth: 1,
        outside: true,
        borderColor: "rgba(100,100,100,0.3)",
        backgroundColor: "rgba(255,255,255,0.95)",
      },

      plotOptions: {
        pie: {
          size: "60%",
          dataLabels: {
            enabled: true,
            distance: 18,
            crop: true,
            padding: 0,
            overflow: "justify",
            formatter: function () {
              return (
                '<p style="font-size: 12px;z-index:-1"> ' +
                this.point.name.split("(")[0] +
                ": " +
                this.y +
                " " +
                //@ts-ignore
                this.point._unit +
                "</p>"
              );
            },
          },

          showInLegend: true,

          startAngle: -90,
          endAngle: 360 - 90,
          center: ["50%", "50%"],
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        // @ts-ignore
        {
          data: seriesDataDays,
          innerSize: "80%",
          turboThreshold: 0,
        },
      ],
    };

    return result;
  }, [seriesDataDays]);

  return (
    <div>
      {isLoading && <Loading message={""} />}
      {!isLoading && (
        <>
          {sickDays > 0 || vacationDays > 0 || workDays > 0 ? (
            <HighchartsChart options={optionsDays} height={250} />
          ) : null}
          {/*<HighchartsChart options={optionsOffice} height={250} />
          <HighchartsChart options={optionsHours} height={250} />*/}
          {billableHours > 0 || notBillableHours > 0 || projectHours > 0 ? (
            <HighchartsChart options={optionCustomer} height={250} />
          ) : null}
          {customerChart.length > 0 && (
            <HighchartsChart options={customerChartOption} height={250} />
          )}
        </>
      )}
    </div>
  );
};

//Chart Anteil Forschunsgprojekt
