import React from "react";
import Parse from "parse";
import { AdminLayout } from "@opendash/core";
import { createComponent } from "@opendash/state";
import { AdminToolbar } from "@opendash/ui";
import { MultiTimeline } from "./Pieces/MultiTimeLine";
import { Flex, Progress, Typography, Col, Row, Card, Statistic } from "antd";
const { Title } = Typography;

interface ProjectData {
  name: string;
  totalMoney: number;
  usedMoney: number;
  percentage: number;
}

export const ProjectOverviewComponent = createComponent(
  function ProjectOverviewComponent() {
    const [projectData, setProjectData] = React.useState<ProjectData[]>([]);
    const [eventData, setEventData] = React.useState<Event[]>([]);
    const [lastDate, setLastDate] = React.useState<Date>(new Date());
    const [isAdmin, setIsAdmin] = React.useState(false);

    React.useEffect(() => {
      checkIfAdmin();
      initProjectData();
    }, []);

    interface Event {
      endDate: Date;
      name: string;
    }

    const checkIfAdmin = async () => {
      const query = new Parse.Query("OD3_Permission").limit(99999999);
      query.equalTo("key", "project:costs");
      const result = await query.find();
      if (result.length > 0) {
        setIsAdmin(true);
      }
    };

    const initProjectData = async () => {
      try {
        let events: Event[] = [];
        const query = new Parse.Query("OD3_Projects").limit(99999999);
        query.ascending("End");
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        query.greaterThanOrEqualTo("End", threeMonthsAgo);
        const result = await query.find();
        result.sort((a, b) => a.get("End") - b.get("End"));
        const projectDataPuffer: ProjectData[] = [];
        const promises = result.map(async (project) => {
          if (project.get("Name") != "open.INC Intern") {
            const queryMoney = new Parse.Query("OD3_ProjectCalls").limit(
              99999999
            );
            queryMoney.equalTo("Projekt", project);
            let resultMoney = await queryMoney.find();
            let usedMoney = 0;
            resultMoney.forEach((money) => {
              usedMoney += money.get("Abrufssumme");
            });
            projectDataPuffer.push({
              name: project.get("Name"),
              totalMoney: project.get("TotalMoney"),
              usedMoney: usedMoney,
              percentage: Math.round(
                (usedMoney / project.get("TotalMoney")) * 100
              ),
            });
            events.push({
              endDate: project.get("End"),
              name: project.get("Name"),
            });
          }
        });

        await Promise.all(promises);
        setProjectData(projectDataPuffer);
        setEventData(events);
        const today = new Date();
        const futureDate = new Date(
          today.getFullYear(),
          today.getMonth() + 36,
          1
        );
        futureDate.setMonth(futureDate.getMonth() + 1);
        futureDate.setDate(0);
        setLastDate(futureDate);
      } catch (error) {
        console.error(error);
      }
    };

    const today = new Date();

    return (
      <AdminLayout>
        <AdminToolbar
          title="Forschungsprojekte"
          description="Übersicht über alle Projekte und deren Laufzeiten."
          search={""}
          actions={[]}
        />
        <div
          className="od-page-main"
          style={{
            width: "100%",
          }}
        >
          <MultiTimeline
            start={today}
            end={lastDate}
            today={today}
            events={eventData}
          />
          <br />
          {isAdmin && (
            <>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Title level={4}>Kostenübersicht</Title>
              </div>
              <Flex wrap gap="small" justify={"space-evenly"}>
                {projectData.map((project) => {
                  return (
                    <div>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "15px",
                        }}
                      >
                        <Card
                          style={{ width: "200px" }}
                          hoverable
                          bordered={false}
                          cover={
                            <Progress
                              type="dashboard"
                              trailColor="rgba(0, 0, 0, 0.06)"
                              strokeWidth={20}
                              steps={10}
                              percent={project.percentage}
                              format={(percent) => (
                                <div style={{ fontSize: "16px" }}>
                                  {percent + "% "} <br />
                                  <div style={{ fontSize: "10px" }}>
                                    {project.name}
                                  </div>
                                </div>
                              )}
                            />
                          }
                        >
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title="Gesamtförderung"
                            value={project.totalMoney}
                            precision={2}
                            valueStyle={{ color: "#3f8600" }}
                            suffix="€"
                          />
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title="Abgerufen"
                            value={project.usedMoney}
                            precision={2}
                            valueStyle={{ color: "#aaa" }}
                            suffix="€"
                          />
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title="Noch verfügbar"
                            value={project.totalMoney - project.usedMoney || 0}
                            precision={2}
                            valueStyle={{ color: "#aaa" }}
                            suffix="€"
                          />
                        </Card>
                      </div>
                    </div>
                  );
                })}
              </Flex>
            </>
          )}
        </div>
      </AdminLayout>
    );
  }
);
