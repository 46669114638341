import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";
import { Space, Select } from "antd";

const { Option } = Select;

export const PayrollComponent = createComponent(function PayrollComponent() {
  const [projectData, setProjectData] = React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState("all");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const query = new Parse.Query("_User").limit(99999999);
        query.equalTo("ignoreInSelection", false);
        query.equalTo("VacationWithoutApplication", false);
        const result = await query.find();
        setProjectData(result);
      } catch (error) {
        console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <AdminLayout>
      <ParseSetView
        className="OD3_LohnBuchungen"
        headerExtra={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Space>
              <Select
                defaultValue="Alle Mitarbeiter"
                onChange={(e) => {
                  setSelectedUser(e);
                }}
                style={{ width: 200, textAlign: "center" }}
              >
                <Option value="all">Alle Mitarbeiter</Option>
                {projectData.map((project, index) => (
                  <Option key={index} value={project.id}>
                    {project.get("name")}
                  </Option>
                ))}
              </Select>
            </Space>
          </div>
        }
        view={
          {
            type: "table",
            customCols: [
              {
                title: "Datum",
                sorter: (a, b) => {
                  const dateA = dayjs(a.get("Datum"));
                  const dateB = dayjs(b.get("Datum"));
                  return dateA.isAfter(dateB) ? 1 : -1;
                },
                sortDirections: ["descend", "ascend", "descend"],
                defaultSortOrder: "descend",
                key: "Datum",
                dataIndex: "Datum",
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("Datum")).format("MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
              {
                title: "Steuerbrutto",
                key: "Steuerbrutto",

                sorter: (a, b) => a.get("SteuerBrutto") - b.get("SteuerBrutto"),
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("SteuerBrutto");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "KV-Beitrag AG",
                key: "KVBeitragAG",
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("KVBeitragAG");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "RV-Beitrag AG",
                key: "RVBeitragAG",
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("RVBeitragAG");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "AV-Beitrag AG",
                key: "AVBeitragAG",
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("AVBeitragAG");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "PV-Beitrag AG",
                key: "PVBeitragAG",
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("PVBeitragAG");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "Umlage 1",
                key: "Umlage1",
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("Umlage1");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "Umlage 2",
                key: "Umlage2",
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("Umlage2");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "Umlage Insolv.",
                key: "UmlageInsolv",
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("UmlageInsolv");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "Auszahlungsbetrag",
                key: "Auszahlungsbetrag",
                dataIndex: 0,
                render: (v, row) => {
                  const variable = row?.get("Auszahlungsbetrag");
                  return (
                    <div>
                      {variable !== undefined && variable !== 0
                        ? variable.toFixed(2) + " €"
                        : "-"}
                    </div>
                  );
                },
              } as ColumnProps<any>,
            ],
            fields: ["Mitarbeiter"],
          } as TableViewType
        }
        customFilter={(query: Parse.Query) => {
          query.descending("Datum");
          if (selectedUser !== "all") {
            query.equalTo("Mitarbeiter", {
              __type: "Pointer",
              className: "_User",
              objectId: selectedUser,
            });
          }
        }}
      />
      <br />
      <br />
      <ParseSetView
        className="OD3_LohnJournale"
        view={
          {
            type: "table",
            fields: ["Document"],
          } as TableViewType
        }
      />
    </AdminLayout>
  );
});
