import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";
import { Select } from "antd";

const { Option } = Select;

export const MoneyCallComponent = createComponent(
  function MoneyCallComponent() {
    const [projectData, setProjectData] = React.useState<any[]>([]);
    const [selectedProject, setSelectedProject] = React.useState("all");

    React.useEffect(() => {
      initProjectData();
    }, []);

    const initProjectData = async () => {
      try {
        const query = new Parse.Query("OD3_Projects").limit(99999999);
        query.descending("End");
        query.notEqualTo("Name", "open.INC Intern");
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 12);
        query.greaterThanOrEqualTo("End", threeMonthsAgo);

        const result = await query.find();
        console.log("🚀 ~ initProjectData ~ result:", result);

        setProjectData(result);
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <AdminLayout>
        <ParseSetView
          headerExtra={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Select
                defaultValue="Alle Projekte"
                style={{ width: 200, textAlign: "left" }}
                onChange={(e) => {
                  setSelectedProject(e);
                }}
              >
                <Option value="all">Alle Projekte</Option>
                {projectData.map((project, index) => (
                  <Option key={index} value={project.id}>
                    {project.get("Name")}
                  </Option>
                ))}
              </Select>
            </div>
          }
          className="OD3_ProjectCalls"
          customFilter={(query: Parse.Query) => {
            query.descending("Anforderungsdatum");
            if (selectedProject !== "all") {
              query.equalTo("Projekt", {
                __type: "Pointer",
                className: "OD3_Projects",
                objectId: selectedProject,
              });
            }
          }}
          view={
            {
              type: "table",
              customCols: [
                {
                  title: "Anforderungsdatum",
                  key: "Anforderungsdatum",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {dayjs(row?.get("Anforderungsdatum")).format(
                          "DD.MM.YYYY"
                        )}
                      </div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Abrufsumme",
                  key: "Abrufsumme",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {row?.get("Abrufssumme").toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        €
                      </div>
                    );
                  },
                } as ColumnProps<any>,
              ],
              fields: ["Projekt", "Abrufart"],
            } as TableViewType
          }
        />
        <br />
        <br />
        <ParseSetView
          className="OD3_Project_Documents"
          view={
            {
              type: "table",
              fields: ["Project", "Document", "createdAt"],
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
