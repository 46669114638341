import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";
import { Space, Select } from "antd";

const { Option } = Select;

export const ContractComponent = createComponent(function ContractComponent() {
  const [projectData, setProjectData] = React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState("all");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const query = new Parse.Query("_User").limit(99999999);
        query.equalTo("ignoreInSelection", false);
        query.equalTo("VacationWithoutApplication", false);
        const result = await query.find();
        setProjectData(result);
      } catch (error) {
        console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <AdminLayout>
      <ParseSetView
        className="OD3_Contract"
        headerExtra={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Space>
              <Select
                defaultValue="Alle Mitarbeiter"
                onChange={(e) => {
                  setSelectedUser(e);
                }}
                style={{ width: 200, textAlign: "center" }}
              >
                <Option value="all">Alle Mitarbeiter</Option>
                {projectData.map((project, index) => (
                  <Option key={index} value={project.id}>
                    {project.get("name")}
                  </Option>
                ))}
              </Select>
            </Space>
          </div>
        }
        customFilter={(query: Parse.Query) => {
          query.descending("End");
          if (selectedUser !== "all") {
            query.equalTo("User", {
              __type: "Pointer",
              className: "_User",
              objectId: selectedUser,
            });
          }
        }}
        view={
          {
            type: "table",
            customCols: [
              {
                title: "Vertragsbeginn",
                key: "Datums",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("Start")).format("DD.MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
              {
                title: "Vertragsende",
                key: "Datums2",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("End")).format("DD.MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
            ],
            fields: [
              "User",
              "HoursADay",
              "VacationDays",
              "ProjectPercent",
              "Status",
              "Salary",
            ],
          } as TableViewType
        }
      />
    </AdminLayout>
  );
});
