import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { Drawer, Statistic, Row, Col } from "antd";
import { ParseSetView } from "@opendash/plugin-parse";
import { ColumnProps } from "antd/lib/table";

import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { Icon } from "@opendash/icons";

interface RequestDrawerProps {
  drawer: boolean;
  setDrawerVisibility: any;
  currentProject: Parse.Object;
}

export const RequestDrawer: React.FC<RequestDrawerProps> = ({
  currentProject,
  drawer,
  setDrawerVisibility,
}) => {
  const onClose = () => {
    setDrawerVisibility(false);
  };

  return (
    <Drawer
      title="Projektabrufe"
      placement="bottom"
      size="large"
      closable={false}
      onClose={onClose}
      open={drawer}
      key="bottom"
    >
      <>
        <ParseSetView
          className="OD3_ProjectCalls"
          hideCreate={true}
          hideEdit={true}
          hideActionBar={true}
          customFilter={(query: Parse.Query) => {
            query.descending("Anforderungsdatum");
            query.equalTo("Projekt", {
              __type: "Pointer",
              className: "OD3_Projects",
              objectId: currentProject.id,
            });
          }}
          view={
            {
              type: "table",
              customCols: [
                {
                  title: "Anforderungsdatum",
                  key: "Anforderungsdatum",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {dayjs(row?.get("Anforderungsdatum")).format(
                          "DD.MM.YYYY"
                        )}
                      </div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Abrufsumme",
                  key: "Abrufsumme",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {row?.get("Abrufssumme").toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        €
                      </div>
                    );
                  },
                } as ColumnProps<any>,
              ],
              fields: ["Projekt", "Abrufart"],
            } as TableViewType
          }
        />
      </>
    </Drawer>
  );
};
