import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { Icon } from "@opendash/icons";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";
import { Select, Space } from "antd";

const { Option } = Select;

export const ProjectTripComponent = createComponent(
  function ProjectTripComponent() {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 6 }, (_, index) => currentYear - index);
    const [projectData, setProjectData] = React.useState<any[]>([]);
    const [selectedYear, setSelectedYear] = React.useState(currentYear);
    const [selectedProject, setSelectedProject] = React.useState("all");

    React.useEffect(() => {
      initProjectData();
    }, []);

    const initProjectData = async () => {
      try {
        const query = new Parse.Query("OD3_Projects").limit(99999999);
        query.descending("End");
        query.notEqualTo("Name", "open.INC Intern");
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 12);
        query.greaterThanOrEqualTo("End", threeMonthsAgo);

        const result = await query.find();
        setProjectData(result);
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <AdminLayout>
        <ParseSetView
          headerExtra={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Space>
                <Select
                  defaultValue="Alle Projekte"
                  style={{ width: 200 }}
                  onChange={(e) => {
                    setSelectedProject(e);
                  }}
                >
                  <Option value="all">Alle Projekte</Option>
                  {projectData.map((project, index) => (
                    <Option key={index} value={project.id}>
                      {project.get("Name")}
                    </Option>
                  ))}
                </Select>
                <Select
                  defaultValue={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e);
                  }}
                  style={{ width: 100 }}
                >
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Space>
            </div>
          }
          className="OD3_Trip"
          customFilter={(query: Parse.Query) => {
            const startDate = new Date(selectedYear, 0, 1);
            const endDate = new Date(selectedYear + 1, 0, 1);
            query.greaterThanOrEqualTo("End", startDate);
            query.lessThan("End", endDate);
            if (selectedProject !== "all") {
              query.equalTo("Project", {
                __type: "Pointer",
                className: "OD3_Projects",
                objectId: selectedProject,
              });
            }
          }}
          view={
            {
              type: "table",
              customCols: [
                {
                  title: "Startdatum",
                  key: "Start",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>{dayjs(row?.get("Start")).format("DD.MM.YYYY")}</div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Enddatum",
                  key: "End",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>{dayjs(row?.get("End")).format("DD.MM.YYYY")}</div>
                    );
                  },
                } as ColumnProps<any>,

                {
                  title: "Beschreibung",
                  key: "Description",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Description")}</div>;
                  },
                } as ColumnProps<any>,

                {
                  title: "Kosten",
                  key: "Costs",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Costs")} €</div>;
                  },
                } as ColumnProps<any>,

                {
                  title: "Belege",
                  key: "Documents",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        <Icon
                          style={{ fontSize: "20px" }}
                          icon="fa:file-pdf"
                          onClick={() => {
                            window.open(row.get("Documents")._url, "_blank");
                          }}
                        ></Icon>
                      </div>
                    );
                  },
                } as ColumnProps<any>,
              ],
              fields: ["Employee", "Project"],
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
