import React from "react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { Row, Col, Select } from "antd";

interface HeaderSelectProps {
  selectedYear: number;
  selectedMonth: number;
  setSelectedYear: (year: number) => void;
  setSelectedMonth: (month: number) => void;
}

export const HeaderSelect: React.FC<HeaderSelectProps> = ({
  selectedYear,
  selectedMonth,
  setSelectedYear,
  setSelectedMonth,
}) => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  // Verwenden Sie selectedYear direkt
  const year: number = selectedYear;
  const month: number = selectedMonth;

  // Funktion, um das Label basierend auf dem Wert zu erhalten
  const getMonthLabel = (value: number): string => {
    if (value >= 0 && value <= 11) {
      return dayjs().month(value).format("MMMM");
    } else if (value >= 12 && value <= 15) {
      const quarters = ["Quartal I", "Quartal II", "Quartal III", "Quartal IV"];
      return quarters[value - 12];
    } else if (value === 16) {
      return "Ganzes Jahr";
    }
    return "";
  };

  // Optionen für den Monat/Quartal/Jahr-Select erstellen
  const getMonthOptions = (): JSX.Element[] => {
    const options: JSX.Element[] = [];

    // Monate hinzufügen
    for (let i = 0; i < 12; i++) {
      const monthName = dayjs().month(i).format("MMMM");
      options.push(
        <Select.Option
          key={`month-${i}`}
          value={i}
          label={monthName}
          className="year-item"
        >
          {monthName}
        </Select.Option>
      );
    }

    // Trennlinie
    options.push(
      <Select.Option key="separator-1" disabled className="separator-option">
        ---------------------
      </Select.Option>
    );

    // Quartale hinzufügen
    const quarters = [
      { label: "Quartal I", value: 12 },
      { label: "Quartal II", value: 13 },
      { label: "Quartal III", value: 14 },
      { label: "Quartal IV", value: 15 },
    ];
    quarters.forEach((quarter) => {
      options.push(
        <Select.Option
          key={`quarter-${quarter.value}`}
          value={quarter.value}
          label={quarter.label}
          className="year-item"
        >
          {quarter.label}
        </Select.Option>
      );
    });

    // Trennlinie
    options.push(
      <Select.Option key="separator-2" disabled className="separator-option">
        ---------------------
      </Select.Option>
    );

    // "Ganzes Jahr" hinzufügen
    options.push(
      <Select.Option
        key="year-option"
        value={16}
        label="Ganzes Jahr"
        className="year-item"
      >
        Ganzes Jahr
      </Select.Option>
    );

    return options;
  };

  const getYearOptions = (): JSX.Element[] => {
    const currentYear: number = dayjs().year();
    const options: JSX.Element[] = [];
    for (let i = currentYear - 1; i <= currentYear + 1; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }
    return options;
  };

  return (
    <div
      style={{
        zIndex: 990,
        padding: "15px",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={24}
          md={24}
          lg={19}
          style={{
            margin: "0px",
            padding: "0px",
          }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={24}
          md={24}
          lg={5}
          style={{
            margin: "0px",
            padding: "0px",
          }}
        >
          {isMobile ? (
            <Row
              gutter={24}
              style={{
                textAlign: "center",
                width: "100%",
                margin: "0px",
                padding: "0px",
              }}
            >
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                  zIndex: 990,
                }}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                    margin: "0px",
                    padding: "0px",
                    zIndex: 990,
                  }}
                  className="my-year-select"
                  onChange={(newYear: number) => {
                    setSelectedYear(newYear);
                  }}
                  value={year}
                >
                  {getYearOptions()}
                </Select>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                  zIndex: 990,
                }}
              >
                <div style={{ width: "100%", height: "10px" }}></div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                  zIndex: 990,
                }}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                    margin: "0px",
                    padding: "0px",
                    zIndex: 990,
                  }}
                  value={{
                    value: selectedMonth,
                    label: getMonthLabel(selectedMonth),
                  }}
                  onChange={(option: any) => {
                    setSelectedMonth(option.value);
                  }}
                  labelInValue
                >
                  {getMonthOptions()}
                </Select>
              </Col>
            </Row>
          ) : (
            <Row gutter={24} style={{ textAlign: "center" }}>
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{ zIndex: 990 }}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  className="my-year-select"
                  onChange={(newYear: number) => {
                    setSelectedYear(newYear);
                  }}
                  value={year}
                >
                  {getYearOptions()}
                </Select>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{ zIndex: 990 }}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  value={{
                    value: selectedMonth,
                    label: getMonthLabel(selectedMonth),
                  }}
                  onChange={(option: any) => {
                    setSelectedMonth(option.value);
                  }}
                  labelInValue
                >
                  {getMonthOptions()}
                </Select>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};
