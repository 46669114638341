import React from "react";
import Parse from "parse";
import { ColumnsType } from "antd/es/table";
import { $parse } from "@opendash/plugin-parse";
import { Icon } from "@opendash/icons";
import { Loading } from "@opendash/core";
import {
  Modal,
  Button,
  Select,
  Checkbox,
  Table,
  Steps,
  InputNumber,
  Popconfirm,
  Input,
  notification,
} from "antd";

const { Option } = Select;
const { Step } = Steps;

interface InvoiceModalProps {
  visible: boolean;
  onClose: () => void;
  customers: Parse.Object[];
  filterList: string[];
  trackingData: any[];
}

export const InvoiceModal: React.FC<InvoiceModalProps> = ({
  visible,
  onClose,
  customers,
  filterList,
  trackingData,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [filteredList, setFilteredList] = React.useState<Parse.Object[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<
    string | null
  >(null);
  const [selectedEntries, setSelectedEntries] = React.useState<string[]>([]);
  const [positions, setPositions] = React.useState<any[]>([]);
  const [individualPositions, setIndividualPositions] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const filteredCustomers = customers.filter((customer) =>
      filterList.includes(customer.id)
    );
    setFilteredList(filteredCustomers);
  }, [filterList, customers]);

  React.useEffect(() => {
    if (selectedEntries.length > 0) {
      let newPositions = [];

      if (individualPositions) {
        const totalWorktime = selectedEntries.reduce(
          (acc: number, entry: any) => acc + parseFloat(entry.worktime) / 60,
          0
        );

        const combinedComment = selectedEntries
          .map((entry: any) => entry.comment)
          .join("\n\n");

        newPositions = [
          {
            key: "combined",
            position: 1,
            product: "Entwicklung nach Aufwand",
            quantity: totalWorktime,
            priceNetto: 137.5,
            discount: 0,
            amount: (totalWorktime * 137.5).toFixed(2),
            comment: combinedComment,
          },
        ];
      } else {
        newPositions = selectedEntries.map((entry, index) => {
          const workTimeInHours = parseFloat(entry.worktime) / 60;
          return {
            key: index,
            position: index + 1,
            product: "Entwicklung nach Aufwand",
            quantity: workTimeInHours,
            priceNetto: 137.5,
            discount: 0,
            amount: (workTimeInHours * 137.5).toFixed(2),
            comment: entry.comment || "",
          };
        });
      }

      setPositions(newPositions);
    }
  }, [selectedEntries, individualPositions]);

  const saveInvoiceEntry = async () => {
    setLoading(true);
    try {
      const invoice = new Parse.Object("OD3_Customer_Invoice");
      invoice.set("Customer", {
        __type: "Pointer",
        className: "OD3_Customer",
        objectId: selectedCustomerId,
      });
      invoice.set("Positions", positions);
      invoice.set("SelectedEntries", selectedEntries);
      invoice.set("Status", "0");
      invoice.set("invoiceDate", new Date());
      invoice.set("sevDeskInvoice", false);
      invoice.set(
        "sumNet",
        positions.reduce(
          (acc, pos) => parseFloat(acc) + parseFloat(pos.amount),
          0
        )
      );

      const aclEntry = new Parse.ACL();
      const currentUserACLEntry = Parse.User.current();
      if (currentUserACLEntry) {
        aclEntry.setReadAccess(currentUserACLEntry, true);
        aclEntry.setWriteAccess(currentUserACLEntry, true);
      }
      aclEntry.setRoleReadAccess(
        "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
        true
      );
      aclEntry.setRoleWriteAccess(
        "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
        true
      );
      aclEntry.setRoleReadAccess("invoice", true);
      aclEntry.setRoleWriteAccess("invoice", false);
      invoice.setACL(aclEntry);

      const response = await invoice.save();

      api.success({
        message: "Rechnung",
        description:
          "Rechnung erfolgreich erstellt und als Entwurf in sevDesk angelegt!",
        placement: "topRight",
      });

      setCurrentStep(0);
      setSelectedCustomerId(null);
      setSelectedEntries([]);
      setPositions([]);
      setIndividualPositions(false);
      onClose();
    } catch (error) {
      console.error("Fehler beim Speichern der Rechnung:", error);
      api.error({
        message: "Rechnung",
        description:
          "Es ist ein fehler aufgetreten in der sevDesk API! Rechnung wurde nicht gespeichert!",
        placement: "topRight",
      });
    }

    setLoading(false);
  };

  const handleCheckboxChange = (e: any) => {
    setIndividualPositions(e.target.checked);
  };

  const handleFieldChange = (value: any, key: string, field: string) => {
    const updatedPositions = [...positions];
    const position = updatedPositions.find((pos) => pos.key === key);

    if (position) {
      position[field] = value;

      const quantity = position.quantity || 0;
      position.amount = (
        quantity * position.priceNetto -
        (position.discount / 100) * (quantity * position.priceNetto)
      ).toFixed(2);

      setPositions(updatedPositions);
    }
  };

  const handleDelete = (key: number) => {
    const updatedPositions = positions.filter(
      (position) => position.key !== key
    );

    updatedPositions.forEach((pos, index) => {
      pos.position = index + 1;
    });

    setPositions(updatedPositions);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const expandableConfig = {
    expandedRowRender: (record: any) => (
      <div style={{ padding: 0 }}>
        <strong>Kommentar:</strong>
        <Input.TextArea
          value={record.comment}
          onChange={(e) =>
            handleFieldChange(e.target.value, record.key, "comment")
          }
          rows={5}
          style={{ width: "100%" }}
        />
      </div>
    ),
    rowExpandable: (record: any) => record.comment,
  };

  const columnsStep2: ColumnsType<any> = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Produkt oder Service",
      dataIndex: "product",
      key: "product",
      render: (value, record) => (
        <Input
          value={value}
          onChange={(e) =>
            handleFieldChange(e.target.value, record.key, "product")
          }
        />
      ),
    },
    {
      title: "Menge in Stunden",
      dataIndex: "quantity",
      key: "quantity",
      render: (value, record) => (
        <InputNumber
          value={value}
          onChange={(value) => handleFieldChange(value, record.key, "quantity")}
          min={0}
          formatter={(value) => `${value} Std.`}
        />
      ),
    },
    {
      title: "Preis (Netto)",
      dataIndex: "priceNetto",
      key: "priceNetto",
      render: (value, record) => (
        <InputNumber
          value={value}
          onChange={(value) =>
            handleFieldChange(value, record.key, "priceNetto")
          }
          formatter={(value) => `${value} €`}
        />
      ),
    },
    {
      title: "Rabatt (%)",
      dataIndex: "discount",
      key: "discount",
      render: (value, record) => (
        <InputNumber
          value={value}
          onChange={(value) => handleFieldChange(value, record.key, "discount")}
          min={0}
          formatter={(value) => `${value}%`}
        />
      ),
    },
    {
      title: "Betrag Netto (€)",
      dataIndex: "amount",
      key: "amount",
      render: (value) => (
        <InputNumber
          value={parseFloat(value)}
          disabled
          formatter={(value) => `${value} €`}
        />
      ),
    },

    {
      title: "Aktionen",
      key: "action",
      render: (_, record) => (
        <Popconfirm
          title="Eintrag löschen?"
          onConfirm={(e) => {
            handleDelete(record.key);
          }}
          onCancel={(e) => {}}
          okText="Löschen"
          cancelText="Abbrechen"
        >
          <Button icon={<Icon icon="fa:trash" key="delete" />} danger />
        </Popconfirm>
      ),
    },
  ];

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCustomerChange = (customerId: string) => {
    setSelectedCustomerId(customerId);
    setSelectedEntries([]);
  };

  let customerEntries =
    trackingData
      .flatMap((tracking) =>
        tracking.Customer.filter(
          (customer: any) => customer.customerID === selectedCustomerId
        ).flatMap((customer: any) => customer.work.map((work: any) => work))
      )
      .map((entry: any, index: number) => ({ ...entry, key: index })) || [];

  //Filter customerEntries to only show entries that are not invoiced yet
  customerEntries = customerEntries.filter(
    (entry: any) => !entry.invoiced && entry.worktime > 0
  );

  const columns = [
    {
      title: "Meta-Daten",
      key: "metadata",
      render: (record: any) => (
        <div style={{ minWidth: "150px" }}>
          <p style={{ margin: 0 }}>{record.user}</p>
          <p style={{ margin: 0 }}>{record.date}</p>
          <p style={{ margin: 0 }}>
            {(parseFloat(record.worktime) / 60).toFixed(2)} Stunden
          </p>
        </div>
      ),
    },
    {
      title: "Kommentar",
      dataIndex: "comment",
      key: "comment",
    },
  ];
  return (
    <Modal
      title="Neue Rechnung erstellen"
      width={1200}
      visible={visible}
      onCancel={() => {
        setCurrentStep(0);
        setSelectedCustomerId(null);
        setSelectedEntries([]);
        setPositions([]);
        setIndividualPositions(false);
        onClose();
      }}
      footer={
        loading
          ? null // Wenn `loading === true`, bleibt der Footer leer
          : [
              <Button key="close" onClick={onClose}>
                Abbrechen
              </Button>,

              currentStep > 0 && (
                <Button
                  key="back"
                  onClick={() => {
                    prevStep();
                  }}
                >
                  Zurück
                </Button>
              ),

              <Button
                key="confirm"
                type="primary"
                disabled={currentStep === 1 && positions.length < 1}
                onClick={() => {
                  if (currentStep < 1) {
                    nextStep();
                  } else {
                    saveInvoiceEntry();
                  }
                }}
              >
                {currentStep < 1 ? "Weiter" : "Absenden"}
              </Button>,
            ]
      }
    >
      {contextHolder}
      {loading && <Loading message="" />}
      {!loading && (
        <>
          <Steps current={currentStep}>
            <Step title="Kundenauswahl" />
            <Step title="Rechnungspositionen" />
          </Steps>
          <div style={{ marginTop: "20px" }}>
            {currentStep === 0 && (
              <>
                <div style={{ marginBottom: "16px" }}>
                  <Select
                    placeholder="Kunde auswählen"
                    style={{ width: "100%" }}
                    onChange={handleCustomerChange}
                    allowClear
                  >
                    {filteredList
                      .sort(
                        (a: Parse.Object, b: Parse.Object) =>
                          a.get("Name").localeCompare(b.get("Name")) // Nach Name sortieren
                      )
                      .map((customer: Parse.Object) => (
                        <Option key={customer.id} value={customer.id}>
                          {customer.get("Name")}
                        </Option>
                      ))}
                  </Select>
                </div>
                {selectedCustomerId && (
                  <>
                    <div style={{ marginBottom: "16px" }}>
                      <Checkbox
                        checked={individualPositions}
                        onChange={handleCheckboxChange}
                      >
                        Einträge zu einer Position zusammenfassen?
                      </Checkbox>
                    </div>
                    <Table
                      rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: selectedEntries.map(
                          (entry) => entry.key
                        ),
                        onChange: (selectedRowKeys) => {
                          const selectedItems = customerEntries.filter(
                            (entry: any) => selectedRowKeys.includes(entry.key)
                          );
                          setSelectedEntries(selectedItems);
                        },
                      }}
                      dataSource={customerEntries}
                      columns={columns}
                      pagination={{ pageSize: 5 }}
                    />
                  </>
                )}
              </>
            )}
            {currentStep === 1 && (
              <div>
                <Table
                  columns={columnsStep2}
                  dataSource={positions}
                  pagination={false}
                  expandable={expandableConfig}
                  rowKey="key"
                />
              </div>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};
