import React from "react";
import Parse from "parse";
import {
  Table,
  Tag,
  Button,
  Popconfirm,
  Badge,
  Tooltip,
  Modal,
  List,
  Space,
  Typography,
  Card,
} from "antd";
import { Icon } from "@opendash/icons";
import type { TableColumnsType } from "antd";

const { Title, Text } = Typography;

interface LicenseTableProps {
  data: object[];
  documentData: object[];
  productData: object[];
  licenseProductData: object[];
  deleteForm: any;
  editEntry: any;
}

export const LicenseTable: React.FC<LicenseTableProps> = ({
  data,
  documentData,
  productData,
  licenseProductData,
  deleteForm,
  editEntry,
}) => {
  const [tableData, setTableData] = React.useState<any>([]);
  const [selectedCell, setSelectedCell] = React.useState<any>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    formatTableData();
  }, [data, documentData]);

  const formatTableData = () => {
    let tableData: DataType[] = [];
    data.forEach((element: any) => {
      let documents: any[] = [];
      documentData.forEach((doc: any) => {
        if (doc.get("License").id === element.id) {
          documents.push(doc);
        }
      });

      let products: any[] = [];
      let invoiceNet = 0;
      licenseProductData.forEach((docLi: any) => {
        if (docLi.get("License").id === element.id) {
          products.push(docLi);

          invoiceNet +=
            parseFloat(docLi.get("Price")) -
            parseFloat(docLi.get("Price")) *
              (parseFloat(docLi.get("Discount")) / 100);
          if (docLi.get("Interval") == "Monatlich") {
            invoiceNet *= 12;
          } else if (docLi.get("Interval") == "Halbjährlich") {
            invoiceNet *= 2;
          } else if (docLi.get("Interval") == "Quartalsweise") {
            invoiceNet *= 4;
          }
        }
      });

      let data = {
        key: element.id,
        kunde: element.get("Kunde").get("Name"),
        invoiceNet: invoiceNet,
        products: products,
        documents: documents,
      };
      tableData.push(data);
    });
    setTableData(tableData);
  };

  const handleOpenModal = (documentID: any) => {
    setSelectedCell(documentID);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDownload = (doc: any) => {
    if (doc.get("File") instanceof Parse.File) {
      const fileUrl = doc.get("File").url(); // URL von Parse.File abrufen
      if (fileUrl) {
        window.open(fileUrl, "_blank"); // Öffnet den Download-Link
      } else {
        console.error("Keine Datei-URL verfügbar");
      }
    } else {
      console.error("Das Dokument ist kein Parse.File");
    }
  };

  interface DataType {
    key: React.Key;
    kunde: string;
    products: string[];
    documents: any[];
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: "Kunde",
      dataIndex: "kunde",
      align: "left",
      sorter: (a, b) => a.kunde.localeCompare(b.kunde),
      defaultSortOrder: "ascend",
      render: (kunde) => {
        const maxLength = 35;
        const isLong = kunde.length > maxLength;
        const displayText = isLong ? `${kunde.slice(0, maxLength)}...` : kunde;

        return (
          <Tooltip title={isLong ? kunde : null}>
            <Space>{displayText}</Space>
          </Tooltip>
        );
      },
    },
    {
      title: "Produkte",
      dataIndex: "products",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            {record.products.map((product: any, index: number) => {
              return (
                <Tooltip
                  title={
                    <div
                      style={{
                        color: "white",
                      }}
                    >
                      <div>
                        <strong>{product.get("Product").get("Label")}</strong>
                      </div>
                      <div>
                        <strong>Typ:</strong>{" "}
                        {product.get("Product").get("Typ")}
                      </div>
                      <div>
                        <strong>Intervall:</strong> {product.get("Interval")}
                      </div>
                      <div>
                        <strong>Preis:</strong>{" "}
                        {parseFloat(product.get("Price"))}€
                      </div>
                      <div>
                        <strong>Rabatt:</strong>{" "}
                        {parseFloat(product.get("Discount"))}%
                      </div>
                      <div>
                        <strong>Gesamt:</strong>{" "}
                        {parseFloat(product.get("Price")) -
                          parseFloat(product.get("Price")) *
                            (parseFloat(product.get("Discount")) / 100)}
                        €
                      </div>
                    </div>
                  }
                >
                  <Tag key={index} color={product.get("Product").get("Color")}>
                    {product.get("Product").get("Label")}
                  </Tag>
                </Tooltip>
              );
            })}
          </>
        );
      },
    },

    {
      title: "Dokumente",
      dataIndex: "documents",
      align: "center",
      render: (_: any, record: any) => {
        if (record.documents.length == 0) {
          return (
            <Tooltip title="Keine Dokumente">
              <Badge count={record.documents.length}>
                <Icon
                  style={{ color: "rgb(195, 80, 80)", fontSize: "25px" }}
                  icon="fa:file-times"
                  onClick={() => handleOpenModal(record)}
                />
              </Badge>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={
                record.documents.length > 0 ? (
                  <div>
                    {record.documents.map((doc: any, index: number) => (
                      <div key={index}>
                        {index + 1}. {doc.get("Label")}
                      </div>
                    ))}
                  </div>
                ) : (
                  "Keine Dokumente"
                )
              }
            >
              <Badge
                count={record.documents.length}
                showZero
                style={{ backgroundColor: "rgb(90, 151, 62)" }}
              >
                <Icon
                  style={{ color: "#7fbb47", fontSize: "25px" }}
                  icon="fa:file-alt"
                  onClick={() => handleOpenModal(record)}
                />
              </Badge>
            </Tooltip>
          );
        }
      },
    },

    {
      title: "Jahresrechnung (Netto)",
      dataIndex: "invoiceNet",
      sorter: (a, b) => a.invoiceNet - b.invoiceNet,
      align: "center",
      render: (_: any, record: any) => {
        return <>{Number(record.invoiceNet).toFixed(2)} €</>;
      },
    },
    {
      title: "",
      dataIndex: "delete",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <Space>
            <Button
              icon={<Icon icon="fa:pen" key="delete" />}
              onClick={(e) => {
                editEntry(record);
              }}
            />

            <Popconfirm
              title="Antrag löschen?"
              onConfirm={(e) => {
                deleteForm(record);
              }}
              onCancel={(e) => {}}
              okText="Löschen"
              cancelText="Abbrechen"
            >
              <Button icon={<Icon icon="fa:trash" key="delete" />} danger />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Dokumente herunterladen"
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null} // Kein Standard-Footer
      >
        {selectedCell?.documents?.length > 0 ? (
          <List
            dataSource={selectedCell?.documents}
            renderItem={(doc: any, index: number) => {
              const description =
                doc.get("Description") || "Keine Beschreibung";
              const updatedAt = doc.updatedAt
                ? new Date(doc.updatedAt).toLocaleDateString("de-DE", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "Unbekannt";
              const label = doc.get("Label") || "Kein Label";
              const file = doc.get("File"); // `file` ist ein Parse.File
              const fileType = file
                ? file.name().split(".").pop()?.toUpperCase() || "Unbekannt"
                : "Unbekannt";

              return (
                <List.Item key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {/* Label und Download-Button */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {index + 1}. {label} ({fileType})
                      </span>
                      <Tooltip title="Herunterladen">
                        <Button
                          type="link"
                          icon={
                            <Icon
                              icon="fa:download"
                              style={{ fontSize: "26px" }}
                            />
                          }
                          onClick={() => handleDownload(doc)}
                        />
                      </Tooltip>
                    </div>

                    {/* Beschreibung */}
                    <div
                      style={{
                        fontStyle: "italic",
                        color: "#888",
                        marginBottom: "2px", // Weniger Abstand nach unten
                      }}
                    >
                      {description}
                    </div>

                    {/* Stand */}
                    <div style={{ fontSize: "12px", color: "#aaa" }}>
                      Stand: {updatedAt} Uhr
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
        ) : (
          <p>Keine Dokumente vorhanden.</p>
        )}
      </Modal>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{ defaultPageSize: 50 }}
        size="small"
      />
    </>
  );
};
