import React from "react";
import dayjs from "dayjs";
import { Descriptions } from "antd";

interface RequestProps {
  personalKosten: any;
  otherKosten: any;
  materialKosten: any;
  tripKosten: any;
  rdKosten: any;
  investKosten: any;
  quarterList: any;
  currentProject: any;
  monthsInQuarter: any;
  selectedQuarter: any;
  overAllCosts: number[];
}

export const ProjectRequest: React.FC<RequestProps> = ({
  personalKosten,
  otherKosten,
  materialKosten,
  tripKosten,
  rdKosten,
  investKosten,
  quarterList,
  currentProject,
  monthsInQuarter,
  selectedQuarter,
  overAllCosts,
}) => {
  const [descriptionProps, setDescriptionProps] = React.useState<any>([]);

  const generateDescriptionItems = () => {
    let items = [
      {
        key: "1",
        labelStyle: { fontWeight: "bold", width: "50%" },
        contentStyle: { fontWeight: "bold", width: "50%" },
        label: "Position Gesamt-Vorkalkulation",
        children: (
          <>
            {!isNaN(Math.floor(quarterList?.getMonth() / 3) + 1)
              ? "Q" + (Math.floor(quarterList?.getMonth() / 3) + 1)
              : "..."}
            {!isNaN(quarterList?.getFullYear())
              ? "/" + quarterList?.getFullYear()
              : " ..."}
            <br />
            <div style={{ fontSize: "12px" }}>
              {monthsInQuarter.length > 0 && selectedQuarter != -1
                ? monthsInQuarter[selectedQuarter]
                    .map((dateString: any) => {
                      return dayjs(dateString, "MM.YYYY").format("MMMM YYYY");
                    })
                    .join(" | ")
                : "Keine Daten vorhanden"}
            </div>
          </>
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "2",
        label: "0813 Material",
        children: (
          <>
            {materialKosten.length == 2
              ? materialKosten[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " "}{" "}
            €
            <div style={{ fontSize: "12px" }}>
              Bisher in:
              {" " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (materialKosten.length == 2
                  ? materialKosten[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"}
            </div>
          </>
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "3",
        label: "0823 FE-Fremdleistung",
        children: (
          <>
            {rdKosten.length == 2
              ? rdKosten[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " "}{" "}
            €
            <div style={{ fontSize: "12px" }}>
              Bisher in:
              {" " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (rdKosten.length == 2
                  ? rdKosten[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"}
            </div>
          </>
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "4",
        label: "0837 Personalkosten",
        children: (
          <>
            {personalKosten
              .reduce(
                (
                  accumulator: any,
                  currentValue: { totalCostsUntilQuarter: any }
                ) => {
                  return accumulator + currentValue.totalCostsUntilQuarter;
                },
                0
              )
              .toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " "}
            €
            <div style={{ fontSize: "12px" }}>
              Bisher in:
              {" " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                personalKosten
                  .reduce(
                    (
                      accumulator: number,
                      currentValue: {
                        workingHoursInOldQuarter: number;
                        costRate: number;
                        overhead: any;
                      }
                    ) => {
                      return (
                        accumulator +
                        currentValue.workingHoursInOldQuarter *
                          currentValue.costRate
                      );
                    },
                    0
                  )
                  .toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) +
                " €"}
            </div>
          </>
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "5",
        label: "0838 Reisekosten",
        children: (
          <>
            {tripKosten.length == 2
              ? tripKosten[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " "}{" "}
            €
            <div style={{ fontSize: "12px" }}>
              Bisher in:
              {" " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (tripKosten.length == 2
                  ? tripKosten[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"}
            </div>
          </>
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "6",
        label: "0847 Abschreibungen",
        children: (
          <>
            {investKosten.length == 2
              ? investKosten[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " "}{" "}
            €
            <div style={{ fontSize: "12px" }}>
              Bisher in:
              {" " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (investKosten.length == 2
                  ? investKosten[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"}
            </div>
          </>
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "7",
        label: "0850 Sonstige Kosten",
        children: (
          <>
            {otherKosten
              .reduce(
                (
                  accumulator: any,
                  currentValue: { totalCostsUntilQuarter: any }
                ) => {
                  return accumulator + currentValue.totalCostsUntilQuarter;
                },
                0
              )
              .toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " "}
            €
            <div style={{ fontSize: "12px" }}>
              Bisher in:
              {" " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                otherKosten
                  .reduce(
                    (
                      accumulator: number,
                      currentValue: {
                        workingHoursInOldQuarter: number;
                        costRate: number;
                        overhead: any;
                      }
                    ) => {
                      return (
                        accumulator +
                        currentValue.workingHoursInOldQuarter *
                          currentValue.costRate *
                          (currentValue.overhead
                            ? 1 + currentProject?.get("Overhead") / 100
                            : 1)
                      );
                    },
                    0
                  )
                  .toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) +
                " €"}
            </div>
          </>
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
      {
        key: "8",
        labelStyle: { fontWeight: "bold" },
        contentStyle: { fontWeight: "bold" },
        label: "Summe",
        children: (
          <>
            {overAllCosts.length == 2
              ? overAllCosts[0].toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0 + " "}{" "}
            €
            <div style={{ fontSize: "12px" }}>
              Bisher in:
              {" " +
                dayjs(quarterList).format("YYYY") +
                ": " +
                (overAllCosts.length == 2
                  ? overAllCosts[1].toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0) +
                " €"}
            </div>
          </>
        ),
        span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
      },
    ];
    setDescriptionProps(items);
  };

  React.useEffect(() => {
    generateDescriptionItems();
  }, [
    personalKosten,
    otherKosten,
    materialKosten,
    tripKosten,
    rdKosten,
    investKosten,
    quarterList,
    currentProject,
    monthsInQuarter,
    selectedQuarter,
    overAllCosts,
  ]);

  return (
    <Descriptions
      style={{ width: "100%" }}
      bordered
      column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
      items={descriptionProps}
    />
  );
};
