import React from "react";
import Parse from "parse";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";
import { Options } from "../Plugin";
import {
  Button,
  Spin,
  Card,
  Collapse,
  Typography,
  Row,
  Col,
  Tag,
  Statistic,
  message,
} from "antd";
import { InvoiceModal } from "./Pieces/InvoiceModal";
import { InvoiceDrawer } from "./Pieces/InvoiceDrawer";

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

type ParseObject = {
  get: (key: string) => any;
  id: string;
};

type WorkEntry = {
  user: string;
  date: string;
  worktime: number;
  comment: string;
  billable: boolean;
  invoiced: boolean;
  trackingID: string;
};

type CustomerEntry = {
  customerID: string;
  customer: string;
  work: WorkEntry[];
};

type GroupedMonth = {
  Date: string;
  Customer: CustomerEntry[];
};

export const ExpensesComponent = observer((props: Partial<Options>) => {
  const [modalVisible, setModalVisibility] = React.useState<boolean>(false);
  const [drawerVisible, setDrawerVisibility] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [trackingData, setTrackingData] = React.useState<any>([]);
  const [customerData, setCustomerData] = React.useState<any>([]);
  const [customerInvoiceData, setCustomerInvoiceData] = React.useState<any>([]);
  const [customerList, setCustomerList] = React.useState<string[]>([]);
  const [customerListWithoutInvoiced, setCustomerListWithoutInvoiced] =
    React.useState<string[]>([]);
  const [customerTrackingData, setCustomerTrackingData] = React.useState<any>(
    []
  );
  const [groupedData, setGroupedData] = React.useState<any>([]);

  const formatDate = (date: string) => {
    const [day, month, year] = date.split(".");
    const dateObj = dayjs(`${year}-${month}-${day}`);
    return dateObj.format("MMMM YYYY");
  };

  React.useEffect(() => {
    initTrackingData();
    initCustomerTrackingData();
    initCustomerData();
    initCustomerInvoiceData();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    setGroupedData(groupByMonthAndCustomer(trackingData, customerTrackingData));
  }, [trackingData, customerTrackingData]);

  const initTrackingData = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTracking")
        .descending("Date")
        .include("User")
        .limit(99999999)
        .greaterThan("Date", new Date("2025-01-01"));
      const result = await query.find();
      setTrackingData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-TimeTracking-Daten:", error);
    }
  };

  const initCustomerTrackingData = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_TimeTrack").limit(99999999);
      query.include("Customer");
      query.include("TimeTracking");
      const result = await query.find();
      setCustomerTrackingData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  const initCustomerData = async () => {
    try {
      const query = new Parse.Query("OD3_Customer")
        .limit(99999999)
        .notEqualTo("ignore", true);
      const result = await query.find();
      setCustomerData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Customer-Daten:", error);
    }
  };

  const initCustomerInvoiceData = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_Invoice").limit(99999999);
      const result = await query.find();
      setCustomerInvoiceData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Customer-Daten:", error);
    }
  };

  const groupByMonthAndCustomer = (
    parseObjects: ParseObject[],
    parseGroupedObjects: ParseObject[]
  ): GroupedMonth[] => {
    let customerList: string[] = [];
    let customerListWithoutInvoiced: string[] = [];
    const getMonthMidDate = (date: Date): string => {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `15.${month.toString().padStart(2, "0")}.${year}`;
    };

    const groupedData: Record<string, GroupedMonth> = {};

    parseObjects.forEach((obj) => {
      const date: Date = obj.get("Date");
      const user = obj.get("User");

      const customers = parseGroupedObjects.filter(
        (obja: any) => obja.get("TimeTracking").id === obj.id
      );

      const monthKey = getMonthMidDate(date);

      if (!groupedData[monthKey]) {
        groupedData[monthKey] = { Date: monthKey, Customer: [] };
      }

      let customerIndex = 0;
      customers.forEach((customer: any) => {
        const billableWork =
          customer.get("Worktime") && customer.get("Billable");

        if (!billableWork) return;

        let customerEntry = groupedData[monthKey].Customer.find(
          (c) => c.customerID === customer.get("Customer").id
        );

        if (customer.get("Worktime") && !customer.get("Invoiced")) {
          customerListWithoutInvoiced.push(customer.get("Customer").id);
        }

        if (!customerEntry) {
          customerList.push(customer.get("Customer").id);
          customerEntry = {
            customerID: customer.get("Customer").id,
            customer: customer.get("Customer").get("Name"),
            work: [],
          };
          groupedData[monthKey].Customer.push(customerEntry);
        }

        if (billableWork) {
          customerEntry.work.push({
            user: user.get("name"),
            date: date.toLocaleDateString(),
            worktime: customer.get("Worktime"),
            comment: customer.get("Comment"),
            billable: customer.get("Billable"),
            invoiced: customer.get("Invoiced"),
            trackingID: customer.id,
          });
        }
        customerIndex++;
      });
    });

    Object.values(groupedData).forEach((month) => {
      month.Customer = month.Customer.filter((customer) =>
        customer.work.some((work) => work.billable === true)
      );
    });
    setCustomerList(customerList);
    setCustomerListWithoutInvoiced(customerListWithoutInvoiced);
    return Object.values(groupedData);
  };

  const DisplayData = () => {
    return (
      <>
        {groupedData.map((tracking: any) => {
          if (tracking.Customer.length === 0) return null;
          tracking.Customer.sort((a, b) => {
            if (a.customer < b.customer) {
              return -1;
            }
            if (a.customer > b.customer) {
              return 1;
            }
            return 0;
          });

          const totalBillableMinutes = tracking.Customer.reduce(
            (acc: number, customer: any) => {
              return (
                acc +
                customer.work
                  .filter((work: any) => work.billable && !work.invoiced) // Annahme: "invoiced" ist ein Flag
                  .reduce(
                    (sum: number, work: any) => sum + parseFloat(work.worktime),
                    0
                  )
              );
            },
            0
          );

          const totalInvoicedMinutes = tracking.Customer.reduce(
            (acc: number, customer: any) => {
              return (
                acc +
                customer.work
                  .filter((work: any) => work.billable && work.invoiced) // Annahme: "invoiced" ist ein Flag
                  .reduce(
                    (sum: number, work: any) => sum + parseFloat(work.worktime),
                    0
                  )
              );
            },
            0
          );

          const totalBillableHours = (totalBillableMinutes / 60).toFixed(2);
          const totalInvoicedHours = (totalInvoicedMinutes / 60).toFixed(2);
          return (
            <div key={tracking.Date} style={{ marginBottom: "10vh" }}>
              <Title level={3}>{formatDate(tracking.Date)}</Title>

              <Row
                gutter={16}
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                <Col xxl={8} xl={12} lg={12} sm={24} xs={24}>
                  <Statistic
                    decimalSeparator=","
                    groupSeparator="."
                    title="Noch Abrechenbar"
                    value={totalBillableHours}
                    suffix="Std."
                  />
                </Col>
                <Col xxl={8} xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Statistic
                    decimalSeparator=","
                    groupSeparator="."
                    title="Anzahl Kunden"
                    value={tracking.Customer.length}
                  />
                </Col>
                <Col xxl={8} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Statistic
                    decimalSeparator=","
                    groupSeparator="."
                    title="Bereits Abgerechnet"
                    value={totalInvoicedHours}
                    suffix="Std."
                  />
                </Col>
              </Row>

              <Collapse defaultActiveKey={["1"]}>
                {tracking.Customer.map((customer: any) => {
                  const invoicedWorkTime = customer.work
                    .filter((work: any) => work.invoiced)
                    .reduce(
                      (acc: number, work: any) =>
                        acc + parseFloat(work.worktime),
                      0
                    );
                  const billableWorktime = customer.work
                    .filter((work: any) => work.billable && !work.invoiced)
                    .reduce(
                      (acc: number, work: any) =>
                        acc + parseFloat(work.worktime),
                      0
                    );

                  const billableWorktimeInHours = (
                    billableWorktime / 60
                  ).toFixed(2);

                  const invoicedWorkTimeInHours = (
                    invoicedWorkTime / 60
                  ).toFixed(2);

                  const uniqueUsers = [
                    ...new Set(
                      customer.work
                        .filter((work: any) => work.billable)
                        .map((work: any) => work.user)
                    ),
                  ];

                  return (
                    <Panel
                      header={
                        <>
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >{`${
                            customer.customer.length > 38
                              ? `${customer.customer.substring(0, 35)}...`
                              : customer.customer
                          }`}</span>
                          <br />

                          {billableWorktime > 0 && (
                            <Tag color="#7fbb47" style={{ marginLeft: "10px" }}>
                              Abrechenbar: {billableWorktimeInHours} Stunden
                            </Tag>
                          )}

                          {invoicedWorkTime > 0 && (
                            <Tag color="#4385c2" style={{ marginLeft: "10px" }}>
                              Abgerechnet: {invoicedWorkTimeInHours} Stunden
                            </Tag>
                          )}

                          {(uniqueUsers as string[]).length > 0 &&
                            (uniqueUsers as string[]).map((user, index) => (
                              <Tag
                                color="#888"
                                key={index}
                                style={{ marginLeft: "5px" }}
                              >
                                {user}
                              </Tag>
                            ))}
                        </>
                      }
                      key={customer.customerID}
                    >
                      <Row gutter={[16, 16]}>
                        {customer.work.map((work: any, index: number) => (
                          <Col span={24} key={index}>
                            <Card style={{ marginBottom: "10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ marginRight: "10px" }}>
                                  <strong>Datum:</strong> {work.date}
                                </div>
                                <div style={{ marginRight: "10px" }}>
                                  <strong>Arbeitszeit:</strong>{" "}
                                  {(parseFloat(work.worktime) / 60).toFixed(2)}{" "}
                                  Stunden{" "}
                                  {work.invoiced && (
                                    <Tag
                                      color="#4385c2"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Abgerechnet
                                    </Tag>
                                  )}
                                </div>
                                <div>
                                  <strong>Mitarbeiter:</strong> {work.user}
                                </div>
                              </div>

                              <div style={{ marginTop: "10px" }}>
                                <p>{work.comment}</p>
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <AdminLayout>
      <AdminToolbar
        title="Kundenaufwände und Rechnungen"
        description="Aktuelle Aufwände für Kunden und erstellen von Rechnungen"
        search={""}
        actions={[
          <Button
            key="0"
            onClick={() => {
              setDrawerVisibility(true);
            }}
          >
            Rechnungen
          </Button>,
          <Button
            key="1"
            onClick={() => {
              setModalVisibility(true);
            }}
          >
            Neue Rechnung
          </Button>,
        ]}
      />

      {loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
          }}
        >
          {DisplayData()}
        </div>
      )}
      <InvoiceModal
        visible={modalVisible}
        onClose={function (): void {
          setModalVisibility(false);
          initTrackingData();
          initCustomerTrackingData();
        }}
        customers={customerData}
        filterList={customerListWithoutInvoiced}
        trackingData={groupedData}
      />
      <InvoiceDrawer
        drawer={drawerVisible}
        setDrawerVisibility={setDrawerVisibility}
      />
    </AdminLayout>
  );
});
