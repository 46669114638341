import React from "react";
import { Icon } from "@opendash/icons";
import { $parse } from "@opendash/plugin-parse";
import Parse from "parse";
import {
  Row,
  Select,
  Col,
  Modal,
  Form,
  Button,
  Tag,
  Input,
  Tooltip,
  Checkbox,
  Popconfirm,
  Statistic,
  message,
} from "antd";

import type { CheckboxProps, InputNumberProps } from "antd";
import { calc } from "antd/es/theme/internal";

interface EntryModalCustomerProps {
  props: any;
  userMeta: any;
  project: any;
  projectID: any;
  projectMeta: any;
  dateClicked: any;
  currentUser: any;
  modalTitle: string;
  modalVisible: boolean;
  hoursWorked: any;
  handleModalOk: any;
  handleModalCancel: any;
  getOwnList: any;
  getAllList: any;
  checkDeleteStatus: any;
  deleteCurrentEntry: any;
  setModalVisible: any;
  setHoursWorked: any;
  parseDataCustomer: any;
}

interface CustomerEntry {
  entryID: string;
  customerID: string;
  customer: string;
  worktime: string;
  comment: string;
  billable: boolean;
}

interface ResearchEntry {
  projectID: string;
  project: string;
  worktime: number;
}

const informationText = `
Gemäß dem Arbeitszeitgesetz gelten folgende Regeln:
Die tägliche Arbeitszeit darf 10 Stunden nicht überschreiten.
Zwischen zwei Arbeitstagen muss eine ununterbrochene Ruhezeit von mindestens 11 Stunden liegen.
Bei mehr als 6 Stunden Arbeit ist eine Pause von mindestens 30 Minuten vorgeschrieben.
Nach 8 Stunden Arbeit ist eine Pause von mindestens 45 Minuten pflicht.
Die maximale wöchentliche Arbeitszeit beträgt 48 Stunden, einschließlich Überstunden.
`;

export const EntryModalCustomer: React.FC<EntryModalCustomerProps> = ({
  props,
  userMeta,
  projectID,
  projectMeta,
  dateClicked,
  currentUser,
  modalTitle,
  modalVisible,
  hoursWorked,
  handleModalOk,
  handleModalCancel,
  getOwnList,
  getAllList,
  project,
  checkDeleteStatus,
  deleteCurrentEntry,
  setModalVisible,
  parseDataCustomer,
}) => {
  const [form] = Form.useForm();
  const [formResearch] = Form.useForm();
  const [note, setNote] = React.useState("");
  const [editCustomrListBefore, setEditCustomerListBefore] = React.useState<
    CustomerEntry[]
  >([]);
  const [autoFill, setAutoFill] = React.useState(true);
  const [customersAll, setCustomersAll] = React.useState<any[]>([]);
  const [disableSave, setDisableSave] = React.useState(false);
  const [customerList, setCustomerList] = React.useState([]);
  const [hoursWorkedThatDay, setHoursWorkedThatDay] =
    React.useState(hoursWorked);
  const [researchHoursWorkedThatDay, setResearchHoursWorkedThatDay] =
    React.useState(hoursWorked * 60);
  const [showInputFields, setShowInputFields] = React.useState(false);
  const [showNotes, setShowNotes] = React.useState(false);
  const [showInputFieldsResearch, setShowInputFieldsResearch] =
    React.useState(false);
  const [vacationChecked, setVacationChecked] = React.useState<boolean>(false);
  const [sickLeaveChecked, setSickLeaveChecked] =
    React.useState<boolean>(false);
  const [homeOffice, setHomeOffice] = React.useState<boolean>(false);
  const [customerEntries, setCustomerEntries] = React.useState<CustomerEntry[]>(
    []
  );
  const [researchEntries, setResearchEntries] = React.useState<ResearchEntry[]>(
    []
  );
  const [currentEntry, setCurrentEntry] = React.useState<Parse.Object | null>(
    null
  );

  const [customers, setCustomers] = React.useState<any[]>([]);
  const [projectName, setProjectName] = React.useState("");
  const [projectIDs, setProjectID] = React.useState("");
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const queue: any = [];
  let isProcessing = false;

  React.useEffect(() => {
    setProjectName(getProjectName(dateClicked));
    setProjectID(projectID(dateClicked));
  }, [userMeta, projectMeta, dateClicked]);

  React.useEffect(() => {
    const filteredData = parseDataCustomer.filter(
      (element: { get: (arg0: string) => any }) => {
        const timeTrackingPointer = element.get("TimeTracking");
        return timeTrackingPointer && timeTrackingPointer.id === getOwnList.id;
      }
    );
    if (getAllList && getAllList.length > 0) {
      const filteredDataAll = parseDataCustomer.filter(
        (element: { get: (arg0: string) => any }) => {
          const timeTrackingPointer = element.get("TimeTracking");
          return (
            timeTrackingPointer &&
            getAllList.some(
              (item: { id: any }) => item.id === timeTrackingPointer.id
            )
          );
        }
      );
      setCustomersAll(filteredDataAll);
    }
    setCustomers(filteredData);
  }, [getOwnList, getAllList, parseDataCustomer, customerEntries]);

  const getProjectName = (date: Date): string => {
    if (userMeta) {
      const filteredProjects = projectMeta?.filter((project: Parse.Object) => {
        return project?.get("Vertrag")?.id === userMeta?.id;
      });

      const matchingProject = filteredProjects?.find(
        (project: Parse.Object) => {
          const start = project?.get("Start");
          const end = project?.get("End");
          return start <= date && date <= end;
        }
      );

      return matchingProject?.get("Projekt")?.get("Name") || "Intern";
    }
    return "Intern";
  }; // Die Funktion wird nur neu erstellt, wenn sich diese ändern

  const handleVacationChange: CheckboxProps["onChange"] = (e) => {
    setVacationChecked(e.target.checked);
    if (e.target.checked) {
      setSickLeaveChecked(false);
    } else {
      setHomeOffice(false);
    }
  };

  const handleSickLeaveChange: CheckboxProps["onChange"] = (e) => {
    setSickLeaveChecked(e.target.checked);
    if (e.target.checked) {
      setVacationChecked(false);
    } else {
      setHomeOffice(false);
    }
  };

  const handleHomeOffice: CheckboxProps["onChange"] = (e) => {
    setHomeOffice(e.target.checked);
  };

  const handleAddCustomerEntry = (values: { currentEntry: CustomerEntry }) => {
    const newEntry = values.currentEntry;
    setCustomerEntries([...customerEntries, newEntry]);
    form.resetFields(["currentEntry"]);
    setShowInputFields(false);
  };

  const handleEditEntry = (entry: any) => {
    setIsEditing(true);
    setCurrentEntry(entry);
    setHoursWorkedThatDay(entry.get("WorkingHours"));
    setVacationChecked(entry.get("Vacation"));
    setSickLeaveChecked(entry.get("Sick"));
    setHomeOffice(entry.get("HomeOffice"));
    setResearchEntries(entry.get("Research"));
    setNote(entry.get("Comment"));
    const customerEntries = customers.map((customer) => {
      return {
        entryID: customer.id,
        customerID: customer.get("Customer").id,
        customer: customer.get("Customer").get("Name"),
        worktime: customer.get("Worktime"),
        comment: customer.get("Comment"),
        billable: customer.get("Billable"),
      };
    });
    setCustomerEntries(customerEntries);
    setEditCustomerListBefore(customerEntries);
  };

  const handleRemoveCustomerEntry = (index: number) => {
    const updatedEntries = customerEntries.filter((_, i) => i !== index);
    setCustomerEntries(updatedEntries);
  };

  const handleRemoveCustomerEntryInEdit = (entry: CustomerEntry) => {
    const matchedCustomer = customers.find(
      (customer) => customer.id === entry.entryID
    );

    if (matchedCustomer) {
      matchedCustomer.destroy();
    }

    const updatedEntries = customerEntries.filter((e) => e !== entry);
    setCustomerEntries(updatedEntries);
  };

  const handleAddResearchEntry = (values: {
    currentEntryResearch: ResearchEntry;
  }) => {
    const newEntry = values.currentEntryResearch;
    setResearchEntries([...researchEntries, newEntry]);
    let researchWorkTime = 0;
    [...researchEntries, newEntry].forEach(
      (entry) => (researchWorkTime += parseInt(entry.worktime))
    );
    setResearchHoursWorkedThatDay(researchWorkTime);
    formResearch.resetFields(["currentEntryResearch"]);
    setShowInputFieldsResearch(false);
  };

  const handleRemoveResearchEntry = (index: number) => {
    const updatedEntries = researchEntries.filter((_, i) => i !== index);
    setResearchEntries(updatedEntries);
    setResearchHoursWorkedThatDay(0);
  };

  const handleCancelInput = () => {
    form.resetFields(["currentEntry"]);
    setShowInputFields(false);
  };

  const handleCancelInputResearch = () => {
    formResearch.resetFields(["currentEntryResearch"]);
    setShowInputFieldsResearch(false);
  };

  const processQueue = async () => {
    if (queue.length === 0) {
      isProcessing = false;
      return;
    }
    isProcessing = true;
    const next = queue.shift();
    await addEntry();
    next();
    processQueue();
  };

  const handleAddButton = () => {
    return new Promise((resolve) => {
      queue.push(resolve);
      if (!isProcessing) {
        processQueue();
      }
    });
  };

  const resetValues = () => {
    setIsEditing(false);
    setShowInputFields(false);
    setShowInputFieldsResearch(false);
    setShowNotes(false);
    setNote("");
    setHoursWorkedThatDay(hoursWorked);
    setVacationChecked(false);
    setSickLeaveChecked(false);
    setHomeOffice(false);
    setCustomerEntries([]);
    setResearchEntries([]);
    setResearchHoursWorkedThatDay(hoursWorked * 60);
    const newEntry: ResearchEntry = {
      projectID: projectIDs,
      project: projectName,
      worktime: hoursWorkedThatDay * 60,
    };
    setResearchEntries([newEntry]);
    formResearch.resetFields(["currentEntryResearch"]);
    setShowInputFieldsResearch(false);
    form.resetFields(["currentEntry"]);
  };

  const handleEditButton = async () => {
    let hoursClean = hoursWorkedThatDay;
    try {
      hoursClean = parseFloat(hoursClean.replace(/,/g, ".")) || hoursClean;
    } catch (error) {}

    researchEntries.forEach((entry) => {
      entry.projectID = projectID(dateClicked);
      entry.worktime = autoFill
        ? Number(researchHoursWorkedThatDay) || 0
        : Number(entry.worktime) || 0;
    });

    let customerSet: CustomerEntry[] = [];
    currentEntry?.set("WorkingHours", parseFloat(hoursWorkedThatDay));
    currentEntry?.set("Vacation", vacationChecked);
    currentEntry?.set("Sick", sickLeaveChecked);
    currentEntry?.set("HomeOffice", homeOffice);
    currentEntry?.set("Research", researchEntries);
    currentEntry?.set("Comment", note);

    customerSet = customerEntries;

    let totalResearchTime = 0;
    let totalCustomerTime = 0;

    customerEntries.forEach((entry) => {
      totalCustomerTime += parseInt(entry.worktime);
    });
    researchEntries.forEach((entry) => {
      totalResearchTime += autoFill
        ? parseFloat(researchHoursWorkedThatDay)
        : parseFloat(entry.worktime);
    });

    if (sickLeaveChecked || vacationChecked) {
      if (sickLeaveChecked) {
        currentEntry?.set("Comment", "Krankheit");
        currentEntry?.set("WorkingHours", 0);
      } else {
        currentEntry?.set("Comment", "Urlaubsantrag");
        currentEntry?.set("WorkingHours", parseFloat(hoursWorked));
      }
      currentEntry?.set("HomeOffice", false);
      currentEntry?.set("Research", []);
      customerSet = [];
    }

    if (
      researchEntries.length == 0 &&
      customerEntries.length == 0 &&
      hoursClean > 0 &&
      !currentUser.get("VacationWithoutApplication")
    ) {
      message.error(
        "Wenn Arbeitszeit mehr als 0 Stunde beträgt, muss ein Forschungsprojekt oder ein Kundenprojekt ausgewählt werden."
      );
    } else if (
      totalCustomerTime + totalResearchTime != hoursClean * 60 &&
      !currentUser.get("VacationWithoutApplication")
    ) {
      message.error(
        "Die totale Arbeitszeit stimmt nich mit den Projekt- und Kundenzeiten zusammen!"
      );
    } else {
      let savedObject = await currentEntry?.save();

      const acl = new Parse.ACL();
      const currentUserACL = currentUser;
      if (currentUserACL) {
        acl.setReadAccess(currentUserACL, true);
        acl.setWriteAccess(currentUserACL, true);
      }
      acl.setRoleReadAccess(
        "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
        true
      );
      acl.setRoleWriteAccess(
        "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
        true
      );

      acl.setRoleReadAccess("invoice", true);
      acl.setRoleWriteAccess("invoice", false);

      for (const entry of customerSet) {
        if (!editCustomrListBefore.includes(entry)) {
          const customerObject = new Parse.Object("OD3_Customer_TimeTrack");
          customerObject.set("Customer", {
            __type: "Pointer",
            className: "OD3_Customer",
            objectId: entry.customer.split("||")[0],
          });
          customerObject.set("TimeTracking", {
            __type: "Pointer",
            className: "OD3_TimeTracking",
            objectId: savedObject?.id,
          });
          customerObject.set("Worktime", parseFloat(entry.worktime));
          customerObject.set("Comment", entry.comment);
          customerObject.set("Billable", entry.billable);
          customerObject.set("Invoiced", false);
          customerObject.setACL(acl);
          await customerObject.save();
        }
      }

      resetValues();
      handleModalOk();
    }
  };

  const handleCancelButton = () => {
    resetValues();
    handleModalCancel();
  };

  const addEntry = async () => {
    let customerSet: CustomerEntry[] = [];

    const timeObject = new Parse.Object("OD3_TimeTracking");

    let hoursClean = hoursWorkedThatDay;
    try {
      hoursClean = parseFloat(hoursClean.replace(/,/g, ".")) || hoursClean;
    } catch (error) {}

    timeObject.set("Date", dateClicked);
    timeObject.set("WorkingHours", parseFloat(hoursClean));
    timeObject.set("Sick", sickLeaveChecked);
    timeObject.set("Vacation", vacationChecked);
    timeObject.set("User", currentUser);
    timeObject.set("Project", 999);
    timeObject.set("HomeOffice", homeOffice);
    timeObject.set("Comment", note);

    timeObject.set("Research", researchEntries);

    customerSet = customerEntries;

    let totalResearchTime = 0;
    let totalCustomerTime = 0;

    customerEntries.forEach((entry) => {
      totalCustomerTime += parseInt(entry.worktime);
    });
    researchEntries.forEach((entry) => {
      totalResearchTime += autoFill
        ? parseFloat(researchHoursWorkedThatDay)
        : parseFloat(entry.worktime);
    });

    if (sickLeaveChecked || vacationChecked) {
      if (sickLeaveChecked) {
        timeObject.set("Comment", "Krankheit");
        timeObject.set("WorkingHours", 0);
      } else {
        timeObject.set("Comment", "Urlaubsantrag");
        timeObject.set("WorkingHours", hoursWorked);
      }
      timeObject.set("HomeOffice", false);
      timeObject.set("Research", []);
      //timeObject.set("Customer", []);
      customerSet = [];
    }

    const acl = new Parse.ACL();
    const currentUserACL = currentUser;
    if (currentUserACL) {
      acl.setReadAccess(currentUserACL, true);
      acl.setWriteAccess(currentUserACL, true);
    }
    acl.setRoleReadAccess(
      "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
      true
    );
    acl.setRoleWriteAccess(
      "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
      true
    );

    acl.setRoleReadAccess("invoice", true);
    acl.setRoleWriteAccess("invoice", false);

    if (
      researchEntries.length == 0 &&
      customerEntries.length == 0 &&
      hoursClean > 0 &&
      !currentUser.get("VacationWithoutApplication")
    ) {
      message.error(
        "Wenn Arbeitszeit mehr als 0 Stunde beträgt, muss ein Forschungsprojekt oder ein Kundenprojekt ausgewählt werden."
      );
    } else if (
      totalCustomerTime + totalResearchTime != hoursClean * 60 &&
      !currentUser.get("VacationWithoutApplication")
    ) {
      message.error(
        "Die totale Arbeitszeit stimmt nich mit den Projekt- und Kundenzeiten zusammen!"
      );
    } else {
      customerEntries.forEach((entry) => {
        entry.customerID = entry.customerID;
        entry.customer = entry.customer;
        entry.billable = entry.billable || false;
      });

      researchEntries.forEach((entry) => {
        entry.projectID = projectID(dateClicked);
        entry.worktime = autoFill
          ? Number(researchHoursWorkedThatDay) || 0
          : Number(entry.worktime) || 0;
      });

      timeObject.setACL(acl);
      let savedObject = await timeObject.save();

      for (const entry of customerSet) {
        const customerObject = new Parse.Object("OD3_Customer_TimeTrack");
        customerObject.set("Customer", {
          __type: "Pointer",
          className: "OD3_Customer",
          objectId: entry.customer.split("||")[0],
        });
        customerObject.set("TimeTracking", {
          __type: "Pointer",
          className: "OD3_TimeTracking",
          objectId: savedObject.id,
        });
        customerObject.set("Worktime", parseFloat(entry.worktime));
        customerObject.set("Comment", entry.comment);
        customerObject.set("Billable", entry.billable);
        customerObject.set("Invoiced", false);
        customerObject.setACL(acl);
        await customerObject.save();
      }

      resetValues();
      handleModalOk();
    }
  };

  React.useEffect(() => {
    const query = new Parse.Query("OD3_Customer").limit(99999999);
    query.equalTo("ignore", false);
    query.ascending("Name");
    query.find().then((results: any) => {
      results.sort((a: any, b: any) => {
        // Konvertiere Namen zu Kleinbuchstaben für Vergleich
        const nameA = a.get("Name").toLowerCase();
        const nameB = b.get("Name").toLowerCase();

        // Absteigende Sortierung (ignoriere Groß-/Kleinschreibung)
        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        return 0;
      });

      // Eintrag mit bestimmtem Namen an die erste Stelle verschieben
      const importantName = "open.INC GmbH"; // Ersetze durch den Namen, der immer an erster Stelle stehen soll
      const index = results.findIndex(
        (obj: any) => obj.get("Name") === importantName
      );
      if (index !== -1) {
        const [importantEntry] = results.splice(index, 1); // Entferne den wichtigen Eintrag
        results.unshift(importantEntry); // Füge ihn an erster Stelle hinzu
      }

      setCustomerList(results);
    });

    const newEntry: ResearchEntry = {
      projectID: projectIDs,
      project: projectName,
      worktime: hoursWorkedThatDay * 60 || 0,
    };
    setResearchEntries([newEntry]);
    formResearch.resetFields(["currentEntryResearch"]);
    setShowInputFieldsResearch(false);
  }, [projectName, projectIDs]);

  React.useEffect(() => {
    //Nicht zuviel Stunden und keine negativen Stunden
    setDisableSave(false);
    if (hoursWorkedThatDay < 0) {
      setHoursWorkedThatDay(0);
    }
    if (hoursWorkedThatDay > 10 && !userMeta?.get("Overtime")) {
      setHoursWorkedThatDay(10);
    }
    let sumOfCustomerHours = 0;
    customerEntries.forEach((entry) => {
      sumOfCustomerHours += parseInt(entry.worktime);
    });
    if (sumOfCustomerHours / 60 > 10) {
      setHoursWorkedThatDay(10);
      setDisableSave(true);
    }

    //Setze ResearchTime automatisch bei nicht Overtime
    let calcHours = hoursWorkedThatDay * 60 - sumOfCustomerHours;
    if (!userMeta?.get("Overtime")) {
      setResearchHoursWorkedThatDay(calcHours < 0 ? 0 : calcHours);
      if (researchHoursWorkedThatDay == 0 && customerEntries.length == 0) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    }

    if (userMeta?.get("Overtime")) {
      //OverTime
      if (researchHoursWorkedThatDay == 0 && customerEntries.length == 0) {
        setDisableSave(true);
      } else {
        if (
          researchHoursWorkedThatDay + sumOfCustomerHours >
          hoursWorkedThatDay * 60
        ) {
          setHoursWorkedThatDay(
            (researchHoursWorkedThatDay + sumOfCustomerHours) / 60
          );
        }
        if (
          researchHoursWorkedThatDay + sumOfCustomerHours <
          hoursWorkedThatDay * 60
        ) {
          setHoursWorkedThatDay(
            (researchHoursWorkedThatDay + sumOfCustomerHours) / 60
          );
        }
      }
    }
  }, [
    researchHoursWorkedThatDay,
    customerEntries,
    hoursWorkedThatDay,
    autoFill,
  ]);

  return (
    <Modal
      title={modalTitle}
      open={modalVisible}
      onOk={
        !getOwnList ? handleAddButton : isEditing ? handleEditButton : () => {}
      }
      okText="Speichern"
      okButtonProps={{
        disabled: disableSave,
        style: { display: !getOwnList || isEditing ? "" : "none" },
      }}
      onCancel={handleCancelButton}
    >
      {getOwnList && !isEditing && (
        <div
          style={{
            marginBottom: 20,
            padding: 16,
            backgroundColor: "#f9f9f9",
            borderRadius: 4,
            border: "1px solid #e8e8e8",
            fontSize: 12,
            color: "#333",
          }}
        >
          <Row align="top" justify="space-between">
            <Col xs={20} sm={22} md={22}>
              <div style={{ marginBottom: 2 }}>
                {!getOwnList.get("Sick") && !getOwnList.get("Vacation") && (
                  <>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col>
                        <Tooltip title={getOwnList.get("Comment")}>
                          <Statistic
                            title={getOwnList.get("User").get("name")}
                            value={getOwnList.get("WorkingHours") + " Stunden"}
                            precision={2}
                          />
                        </Tooltip>
                      </Col>
                      {checkDeleteStatus(getOwnList) && (
                        <Col
                          style={{
                            textAlign: "right",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "24px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleEditEntry(getOwnList);
                            }}
                          >
                            <Icon
                              icon="fa:pen"
                              style={{ marginRight: 16, fontSize: "28px" }}
                            />
                          </div>
                          <Popconfirm
                            title="Wirklich löschen?"
                            onConfirm={(e) => {
                              deleteCurrentEntry(getOwnList);
                              setModalVisible(false);
                            }}
                            onCancel={(e) => {}}
                            okText="Löschen"
                            cancelText="Abbrechen"
                          >
                            <div
                              style={{
                                color: "red",
                                fontSize: "28px",
                                cursor: "pointer",
                              }}
                              onClick={() => {}}
                            >
                              <Icon icon="fa:trash" />
                            </div>
                          </Popconfirm>
                        </Col>
                      )}
                    </Row>

                    {/* Tags unter der ersten Reihe */}
                    <div style={{ lineHeight: "5px", marginTop: 8 }}>
                      {getOwnList.get("HomeOffice") && (
                        <Tag
                          color="orange"
                          style={{
                            margin: 2,
                            maxWidth: "120%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Home-Office
                        </Tag>
                      )}
                      {getOwnList
                        .get("Research")
                        ?.map((entry: any, index: number) => (
                          <Tag
                            key={index}
                            style={{
                              margin: 2,
                              maxWidth: "120%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            color="#7fbb47"
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span>
                                <strong>
                                  {entry.project.length > 15
                                    ? entry.project.slice(0, 12) + "..."
                                    : entry.project}
                                </strong>
                                {" | "}
                                <span>
                                  {(() => {
                                    const worktime =
                                      Number(entry.worktime) || 0;
                                    const hours = Math.floor(worktime / 60);
                                    const minutes = worktime % 60;
                                    return `${hours}:${minutes
                                      .toString()
                                      .padStart(2, "0")} Stunden`;
                                  })()}
                                </span>
                              </span>
                            </div>
                          </Tag>
                        ))}
                      {customers?.map((entry: any, index: number) => (
                        <Tooltip title={entry.get("Comment")} key={index}>
                          <Tag
                            color="#4385c2"
                            style={{
                              margin: 2,
                              maxWidth: "120%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span>
                                <strong>
                                  {entry.get("Customer").get("Name").length > 15
                                    ? entry
                                        .get("Customer")
                                        .get("Name")
                                        .slice(0, 12) + "..."
                                    : entry.get("Customer").get("Name")}
                                </strong>
                                {" | "}
                                <span>
                                  {(() => {
                                    const worktime =
                                      Number(entry.get("Worktime")) || 0;
                                    const hours = Math.floor(worktime / 60);
                                    const minutes = worktime % 60;
                                    return `${hours}:${minutes
                                      .toString()
                                      .padStart(2, "0")} Stunden`;
                                  })()}
                                </span>
                                {" | "}
                                <span>
                                  {entry.get("Billable")
                                    ? "Abrechnung"
                                    : "Keine Abrechnung"}
                                </span>
                              </span>
                            </div>
                          </Tag>
                        </Tooltip>
                      ))}
                    </div>
                  </>
                )}
                {getOwnList.get("Sick") && (
                  <Statistic
                    title={getOwnList.get("User").get("name")}
                    value={"Krankheit"}
                    precision={2}
                  />
                )}
                {getOwnList.get("Vacation") && (
                  <Statistic
                    title={getOwnList.get("User").get("name")}
                    value={"Urlaub"}
                    precision={2}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
      {getAllList.length > 0 &&
        !isEditing &&
        getAllList.map((element: any, index: number) => (
          <div
            key={index}
            style={{
              marginBottom: 20,
              padding: 16,
              backgroundColor: "#fefefe",
              borderRadius: 4,
              border: "1px dashed #a1a1a1",
              fontSize: 12,
              color: "#333",
            }}
          >
            <Row align="top" justify="space-between">
              <Col xs={20} sm={22} md={22}>
                <div style={{ marginBottom: 2 }}>
                  {!element.get("Sick") && !element.get("Vacation") && (
                    <>
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Tooltip title={element.get("Comment")}>
                            <Statistic
                              title={element.get("User").get("name")}
                              value={element.get("WorkingHours") + " Stunden"}
                              precision={2}
                            />
                          </Tooltip>
                        </Col>
                        {checkDeleteStatus(element) && (
                          <Col
                            style={{
                              textAlign: "right",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Popconfirm
                              title="Wirklich löschen?"
                              onConfirm={(e) => {
                                deleteCurrentEntry(element);
                                setModalVisible(false);
                              }}
                              onCancel={(e) => {}}
                              okText="Löschen"
                              cancelText="Abbrechen"
                            >
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "24px",
                                  cursor: "pointer",
                                }}
                              >
                                <Icon icon="fa:trash" />
                              </div>
                            </Popconfirm>
                          </Col>
                        )}
                      </Row>

                      {/* Tags unter der ersten Reihe */}
                      <div style={{ lineHeight: "5px", marginTop: 8 }}>
                        {element.get("HomeOffice") && (
                          <Tag
                            color="orange"
                            style={{
                              margin: 2,
                              maxWidth: "120%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Home-Office
                          </Tag>
                        )}
                        {element
                          .get("Research")
                          ?.map((entry: any, index: number) => (
                            <Tag
                              key={index}
                              style={{
                                margin: 2,
                                maxWidth: "120%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              color="#7fbb47"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>
                                  <strong>
                                    {entry.project.length > 15
                                      ? entry.project.slice(0, 12) + "..."
                                      : entry.project}
                                  </strong>
                                  {" | "}
                                  <span>
                                    {(() => {
                                      const worktime =
                                        Number(entry.worktime) || 0;
                                      const hours = Math.floor(worktime / 60);
                                      const minutes = worktime % 60;
                                      return `${hours}:${minutes
                                        .toString()
                                        .padStart(2, "0")} Stunden`;
                                    })()}
                                  </span>
                                </span>
                              </div>
                            </Tag>
                          ))}
                        {customersAll.map((entry: any, index: number) => {
                          if (entry.get("TimeTracking").id === element.id) {
                            return (
                              <Tooltip title={entry.get("Comment")} key={index}>
                                <Tag
                                  color="#4385c2"
                                  style={{
                                    margin: 2,
                                    maxWidth: "120%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      <strong>
                                        {entry.get("Customer").get("Name")
                                          .length > 15
                                          ? entry
                                              .get("Customer")
                                              .get("Name")
                                              .slice(0, 12) + "..."
                                          : entry.get("Customer").get("Name")}
                                      </strong>
                                      {" | "}
                                      <span>
                                        {(() => {
                                          const worktime =
                                            Number(entry.get("Worktime")) || 0;
                                          const hours = Math.floor(
                                            worktime / 60
                                          );
                                          const minutes = worktime % 60;
                                          return `${hours}:${minutes
                                            .toString()
                                            .padStart(2, "0")} Stunden`;
                                        })()}
                                      </span>
                                      {" | "}
                                      <span>
                                        {entry.get("Billable")
                                          ? "Abrechnung"
                                          : "Keine Abrechnung"}
                                      </span>
                                    </span>
                                  </div>
                                </Tag>
                              </Tooltip>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </>
                  )}
                  {element.get("Sick") && (
                    <Statistic
                      title={element.get("User").get("name")}
                      value={"Krankheit"}
                      precision={2}
                    />
                  )}
                  {element.get("Vacation") && (
                    <Statistic
                      title={element.get("User").get("name")}
                      value={"Urlaub"}
                      precision={2}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ))}
      {(!getOwnList || isEditing) && (
        <>
          <>
            <h3>Arbeitszeit</h3>
            {!(vacationChecked || sickLeaveChecked) && (
              <div
                style={{
                  width: "100%",
                  height: "30px",
                  textAlign: "center",
                }}
              >
                <span>
                  <Icon icon="fa:info-circle" />
                </span>{" "}
                Pausenzeiten werden nicht automatisch abgezogen.
              </div>
            )}
            <Row gutter={[0, 0]}>
              <Col span={24}>
                {!(vacationChecked || sickLeaveChecked) && (
                  <>
                    <Input
                      type="number"
                      style={{
                        height: "30px",
                        width: "100%",
                        textAlign: "center",
                        paddingLeft: "45%",
                      }}
                      prefix={
                        <Icon icon="fa:clock" style={{ marginLeft: "-45%" }} />
                      }
                      suffix="Stunden"
                      value={hoursWorkedThatDay}
                      onChange={(e) => {
                        try {
                          let value = e.target.value;
                          setHoursWorkedThatDay(value);
                        } catch (e) {
                          console.error(e);
                        }
                      }}
                    />
                  </>
                )}
              </Col>
            </Row>

            <div style={{ height: "10px", width: "100%" }}></div>
            <div
              style={{
                width: "100%",
                height: "30px",
                border: "1px solid lightgray",
              }}
            >
              <Row
                justify="center"
                align="middle"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                {currentUser.get("VacationWithoutApplication") && (
                  <Col style={{ marginRight: "20px" }}>
                    {" "}
                    {/* Abstand zwischen den Checkboxen */}
                    <Checkbox
                      checked={vacationChecked}
                      onChange={handleVacationChange}
                    >
                      Urlaub
                    </Checkbox>
                  </Col>
                )}
                <Col style={{ marginRight: "20px" }}>
                  <Checkbox
                    checked={sickLeaveChecked}
                    onChange={handleSickLeaveChange}
                  >
                    Krankheit
                  </Checkbox>
                </Col>
                {!(vacationChecked || sickLeaveChecked) && (
                  <Col>
                    <Checkbox checked={homeOffice} onChange={handleHomeOffice}>
                      Home-Office
                    </Checkbox>
                  </Col>
                )}
              </Row>
            </div>

            {!(vacationChecked || sickLeaveChecked) && (
              <>
                <br />
                <br />
                <Row gutter={[16, 16]}>
                  {props.researchprojectPlugin && <h3>Forschungsprojekt</h3>}
                  {!props.researchprojectPlugin && <h3>Interne Zeit</h3>}

                  <Col span={24}>
                    <Form
                      initialValues={{
                        currentEntryResearch: {
                          projectID: projectIDs, // Der Standardwert für die Projekt-ID
                          project: projectName, // Der Standardwert für das Projekt
                        },
                      }}
                      form={formResearch}
                      name="dynamic_form_research"
                      onFinish={handleAddResearchEntry}
                      autoComplete="off"
                    >
                      {researchEntries.map((entry, index) => {
                        const worktime = autoFill
                          ? researchHoursWorkedThatDay
                          : Number(entry.worktime) || 0;
                        const calcHoursADay = worktime;
                        const hours = Math.floor(calcHoursADay / 60);
                        const minutes = calcHoursADay % 60;
                        const formattedWorktime = `${hours}:${minutes
                          .toString()
                          .padStart(2, "0")} Stunden`;

                        return (
                          <Tag
                            key={index}
                            color="#7fbb47"
                            style={{
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between", // Elemente verteilen sich gleichmäßig
                              padding: "4px 8px",
                              borderRadius: 4,
                              marginBottom: 8,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                paddingLeft: 8,
                                paddingRight: 8,
                                alignItems: "center",
                                justifyContent: "space-between", // Platz für die Inhalte
                                flex: 1, // Nimmt die gesamte Breite ein
                              }}
                            >
                              <span
                                style={{
                                  justifyContent: "space-between",
                                  flex: 1,
                                }}
                              >
                                <span>
                                  <strong>{entry.project}</strong>
                                  <>
                                    {" | "}
                                    <span>{formattedWorktime}</span>
                                  </>
                                </span>
                              </span>
                            </div>

                            <Tooltip title="Eintrag entfernen">
                              <Icon
                                icon="fa:trash"
                                style={{
                                  marginLeft: 8,
                                  cursor: "pointer",
                                  fontSize: "16px", // Icon-Größe
                                }}
                                onClick={() => handleRemoveResearchEntry(index)}
                              />
                            </Tooltip>
                          </Tag>
                        );
                      })}

                      {showInputFieldsResearch && (
                        <div
                          style={{
                            border: "1px solid #d9d9d9",
                            padding: 16,
                            marginBottom: 16,
                            borderRadius: 4,
                          }}
                        >
                          <Form.Item
                            name={["currentEntryResearch", "project"]}
                            initialValue={projectName}
                            rules={[
                              {
                                required: true,
                                message: "Bitte Projekt auswählen",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Projekt auswählen"
                              options={[
                                {
                                  value: projectName,
                                  label: projectName,
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            name={["currentEntryResearch", "worktime"]}
                            initialValue={hoursWorkedThatDay * 60}
                            rules={[
                              {
                                required: true,
                                message: "Bitte Projektanteil auswählen",
                              },
                            ]}
                          >
                            <Select
                              style={{ flex: 1 }}
                              onChange={(e) => {
                                if (e === hoursWorkedThatDay * 60) {
                                  setAutoFill(true);
                                } else {
                                  setAutoFill(false);
                                }
                              }}
                            >
                              <Select.Option
                                key={"pauschale"}
                                value={hoursWorkedThatDay * 60}
                              >
                                Restzeit auf Forschungsprojekt buchen
                              </Select.Option>
                              {[
                                { value: "15", label: "0:15 Stunden" },
                                { value: "30", label: "0:30 Stunden" },
                                { value: "60", label: "1:00 Stunden" },
                                { value: "90", label: "1:30 Stunden" },
                                { value: "120", label: "2:00 Stunden" },
                                { value: "150", label: "2:30 Stunden" },
                                { value: "180", label: "3:00 Stunden" },
                                { value: "210", label: "3:30 Stunden" },
                                { value: "240", label: "4:00 Stunden" },
                                { value: "270", label: "4:30 Stunden" },
                                { value: "300", label: "5:00 Stunden" },
                                { value: "330", label: "5:30 Stunden" },
                                { value: "360", label: "6:00 Stunden" },
                                { value: "390", label: "6:30 Stunden" },
                                { value: "420", label: "7:00 Stunden" },
                                { value: "450", label: "7:30 Stunden" },
                                { value: "480", label: "8:00 Stunden" },
                              ].map(({ value, label }) => (
                                <Select.Option key={value} value={value}>
                                  {label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Row gutter={16}>
                            <Col span={12}>
                              <Button type="primary" htmlType="submit" block>
                                Hinzufügen
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Button
                                type="default"
                                onClick={handleCancelInputResearch}
                                block
                              >
                                Abbrechen
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Form>

                    {!showInputFieldsResearch && researchEntries.length < 1 && (
                      <>
                        <Button
                          type="dashed"
                          onClick={() => {
                            setAutoFill(true);
                            let sumOfCustomerHours = 0;
                            customerEntries.forEach((entry) => {
                              sumOfCustomerHours += parseInt(entry.worktime);
                            });
                            const newEntry: ResearchEntry = {
                              projectID: projectIDs,
                              project: projectName,
                              worktime:
                                Number(hoursWorkedThatDay * 60) -
                                  sumOfCustomerHours || 0,
                            };
                            setResearchEntries([newEntry]);
                            setResearchHoursWorkedThatDay(newEntry.worktime);
                            formResearch.resetFields(["currentEntryResearch"]);
                            setShowInputFieldsResearch(false);
                          }}
                          icon={<Icon icon="fa:plus" />}
                          block
                        >
                          Restzeit auf Forschungsprojekt
                        </Button>
                        <div style={{ height: "10px", width: "100%" }}></div>
                        <Button
                          type="dashed"
                          onClick={() => {
                            formResearch.setFieldsValue({
                              currentEntryResearch: {
                                projectID: projectIDs,
                                project: projectName,
                              },
                            });
                            setShowInputFieldsResearch(true);
                          }}
                          icon={<Icon icon="fa:plus" />}
                          block
                        >
                          Neue Projektzeit hinzufügen
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
                {!currentUser.get("VacationWithoutApplication") && (
                  <>
                    <br />
                    <br />
                  </>
                )}
                {!currentUser.get("VacationWithoutApplication") && (
                  <Row gutter={[16, 16]}>
                    <h3>Kundenprojekt</h3>
                    <Col span={24}>
                      <Form
                        form={form}
                        name="dynamic_form"
                        onFinish={handleAddCustomerEntry}
                        autoComplete="off"
                      >
                        {customerEntries.map((entry, index) => {
                          const worktime = Number(entry.worktime) || 0;
                          const hours = Math.floor(worktime / 60);
                          const minutes = worktime % 60;
                          const formattedWorktime = `${hours}:${minutes
                            .toString()
                            .padStart(2, "0")} Stunden`;

                          let customerName;

                          if (!entry.entryID) {
                            // Wenn entryID nicht vorhanden ist, splitte entry.customer und nimm das Element mit Index 1
                            const customerParts = entry.customer.split("||");
                            customerName = customerParts[1] || "";
                          } else {
                            // Wenn entryID vorhanden ist, verwende entry.customer ohne zu splitten
                            customerName = entry.customer;
                          }

                          // Kürze den customerName, falls er länger als 30 Zeichen ist
                          customerName =
                            customerName.length > 30
                              ? customerName.slice(0, 27) + "..."
                              : customerName;

                          return (
                            <Tag
                              key={index}
                              color="#4385c2"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between", // Elemente verteilen sich gleichmäßig
                                padding: "4px 8px",
                                borderRadius: 4,
                                marginBottom: 8,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  alignItems: "center",
                                  justifyContent: "space-between", // Platz für die Inhalte
                                  flex: 1, // Nimmt die gesamte Breite ein
                                }}
                              >
                                <span
                                  style={{
                                    textAlign: "center",
                                    justifyContent: "space-between",
                                    flex: 1,
                                  }}
                                >
                                  <span>
                                    <strong>{customerName}</strong>
                                  </span>{" "}
                                  | <span>{formattedWorktime}</span> |{" "}
                                  <span>
                                    {entry.billable
                                      ? "Abrechnung"
                                      : "Keine Abrechnung"}
                                  </span>
                                </span>
                              </div>
                              {isEditing && (
                                <Popconfirm
                                  title="Der Eintrag wird direkt unwiderruflich gelöscht!"
                                  onConfirm={(e) => {
                                    handleRemoveCustomerEntryInEdit(entry);
                                  }}
                                  onCancel={(e) => {}}
                                  okText="Löschen"
                                  cancelText="Abbrechen"
                                >
                                  <Tooltip title="Eintrag entfernen">
                                    <Icon
                                      icon="fa:trash"
                                      style={{
                                        marginLeft: 8,
                                        cursor: "pointer",
                                        fontSize: "16px", // Icon-Größe
                                      }}
                                    />
                                  </Tooltip>
                                </Popconfirm>
                              )}
                              {!isEditing && (
                                <Tooltip title="Eintrag entfernen">
                                  <Icon
                                    icon="fa:trash"
                                    style={{
                                      marginLeft: 8,
                                      cursor: "pointer",
                                      fontSize: "16px", // Icon-Größe
                                    }}
                                    onClick={() =>
                                      handleRemoveCustomerEntry(index)
                                    }
                                  />
                                </Tooltip>
                              )}
                            </Tag>
                          );
                        })}

                        {showInputFields && (
                          <div
                            style={{
                              border: "1px solid #d9d9d9",
                              padding: 16,
                              marginBottom: 16,
                              borderRadius: 4,
                            }}
                          >
                            <Form.Item
                              name={["currentEntry", "customer"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Bitte Kunde auswählen",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Kunde auswählen"
                                showSearch
                                options={customerList.map((customer) => ({
                                  //@ts-ignore
                                  value:
                                    customer.id + "||" + customer.get("Name"), //@ts-ignore
                                  label: customer.get("Name"), // Name des Kunden
                                }))}
                                filterOption={
                                  (input, option) =>
                                    option?.label
                                      .toLowerCase()
                                      .includes(input.toLowerCase()) // Suche nach Label
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              name={["currentEntry", "worktime"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Bitte Arbeitszeit auswählen",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Arbeitszeit auswählen"
                                options={[
                                  {
                                    value: "15",
                                    label: "0:15 Stunden",
                                  },
                                  {
                                    value: "30",
                                    label: "0:30 Stunden",
                                  },
                                  {
                                    value: "60",
                                    label: "1:00 Stunden",
                                  },
                                  {
                                    value: "90",
                                    label: "1:30 Stunden",
                                  },
                                  {
                                    value: "120",
                                    label: "2:00 Stunden",
                                  },
                                  {
                                    value: "150",
                                    label: "2:30 Stunden",
                                  },
                                  {
                                    value: "180",
                                    label: "3:00 Stunden",
                                  },
                                  {
                                    value: "210",
                                    label: "3:30 Stunden",
                                  },
                                  {
                                    value: "240",
                                    label: "4:00 Stunden",
                                  },
                                  {
                                    value: "270",
                                    label: "4:30 Stunden",
                                  },
                                  {
                                    value: "300",
                                    label: "5:00 Stunden",
                                  },
                                  {
                                    value: "330",
                                    label: "5:30 Stunden",
                                  },
                                  {
                                    value: "360",
                                    label: "6:00 Stunden",
                                  },
                                  {
                                    value: "390",
                                    label: "6:30 Stunden",
                                  },
                                  {
                                    value: "420",
                                    label: "7:00 Stunden",
                                  },
                                  {
                                    value: "450",
                                    label: "7:30 Stunden",
                                  },
                                  {
                                    value: "480",
                                    label: "8:00 Stunden",
                                  },
                                  {
                                    value: "510",
                                    label: "8:30 Stunden",
                                  },
                                  {
                                    value: "540",
                                    label: "9:00 Stunden",
                                  },
                                  {
                                    value: "570",
                                    label: "9:30 Stunden",
                                  },
                                  {
                                    value: "600",
                                    label: "10:00 Stunden",
                                  },
                                ]}
                              />
                            </Form.Item>
                            <Form.Item
                              name={["currentEntry", "comment"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Bitte Kommentar eingeben",
                                },
                              ]}
                            >
                              <Input.TextArea
                                placeholder="Kommentar"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                              />
                            </Form.Item>
                            <Form.Item
                              name={["currentEntry", "billable"]}
                              valuePropName="checked"
                            >
                              <Checkbox>Abrechnungsfähig</Checkbox>
                            </Form.Item>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Button type="primary" htmlType="submit" block>
                                  Hinzufügen
                                </Button>
                              </Col>
                              <Col span={12}>
                                <Button
                                  type="default"
                                  onClick={handleCancelInput}
                                  block
                                >
                                  Abbrechen
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Form>

                      {!showInputFields && (
                        <Button
                          type="dashed"
                          onClick={() => {
                            setShowInputFields(true);
                          }}
                          icon={<Icon icon="fa:plus" />}
                          block
                        >
                          Neue Kundenzeit hinzufügen
                        </Button>
                      )}
                    </Col>
                  </Row>
                )}
                <br />
                <br />
                <Row gutter={[16, 16]}>
                  <h3>Notiz</h3>
                  <Col span={24}>
                    {showNotes && (
                      <div
                        style={{
                          border: "1px solid #d9d9d9",
                          padding: 16,
                          marginBottom: 16,
                          borderRadius: 4,
                        }}
                      >
                        <div style={{ paddingBottom: "24px" }}>
                          <Input.TextArea
                            placeholder="Hier können Sie eine Notiz hinzufügen"
                            autoSize={{ minRows: 3, maxRows: 10 }}
                            value={note} // Bindung an den State
                            onChange={(e) => setNote(e.target.value)} //
                          />
                        </div>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              block
                              onClick={() => {
                                setShowNotes(false);
                              }}
                            >
                              Hinzufügen
                            </Button>
                          </Col>
                          <Col span={12}>
                            <Button
                              type="default"
                              onClick={() => {
                                setShowNotes(false);
                                setNote("");
                              }}
                              block
                            >
                              {note == "" ? "Abbrechen" : "Löschen"}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {!showNotes && (
                      <>
                        {note != "" && <p>{note}</p>}
                        <Button
                          type="dashed"
                          onClick={() => {
                            setShowNotes(true);
                          }}
                          icon={<Icon icon="fa:plus" />}
                          block
                        >
                          {note == "" ? "Notiz hinzufügen" : "Notiz bearbeiten"}
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>

                <br />
              </>
            )}
          </>
        </>
      )}
      {(!getOwnList || isEditing) && !(vacationChecked || sickLeaveChecked) && (
        <div>
          <p
            style={{
              marginBottom: 8,
              marginTop: 20,
              padding: 12,
              paddingBottom: 29,
              textAlign: "justify",
              backgroundColor: "rgba(252, 186, 3, 0.02)",
              borderRadius: 4,
              border: "1px dashed #fcba03",
              fontSize: 9,
              color: "#333",
            }}
          >
            {informationText}
          </p>
        </div>
      )}
    </Modal>
  );
};
